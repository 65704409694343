define("lh4/routes/authenticated/epro/menu/dynamic-pricing/edit", ["exports", "lh4/routes/general-edit-route", "moment", "lh4/utils/time/time-array"], function (_exports, _generalEditRoute, _moment, _timeArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var DEFAULT_START_OF_DAY = '04:00 AM';
  var TIME_OPTION_INTERVAL_MINUTES = 15;

  var _default = _generalEditRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    intradayPeriod: Ember.inject.service('intraday-period'),
    locationSettings: Ember.inject.service('echo-pro/settings'),
    router: Ember.inject.service(),
    modelName: 'dynamicPrice',
    childChangesets: {
      schedules: 'scheduleChangesets'
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      this.router.transitionTo('authenticated.epro.menu.dynamic-pricing');
    }),
    model: function model(_ref) {
      var scheduleTitleGuid = _ref.scheduleTitleGuid;
      return Ember.RSVP.hash({
        dynamicPrice: this.store.findRecord('echo-pro/availability-price/dynamic-price', scheduleTitleGuid),
        startOfDay: this.locationSettings.getEoDSetting(this.location.model.id),
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: this.location.model.id
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref2) {
            var guid = _ref2.guid,
                revenueCenterName = _ref2.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        intradayPeriodOptions: this.intradayPeriod.getDayPartsGroups(this.location.model.id).then(function (intradayPeriods) {
          return intradayPeriods.map(function (_ref3) {
            var guid = _ref3.guid,
                intradayPeriodGroupName = _ref3.intradayPeriodGroupName;
            return {
              value: guid,
              name: intradayPeriodGroupName
            };
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      var formatTime = function formatTime(milliseconds) {
        var time = _moment.default.utc(milliseconds).format('h:mm A');

        return milliseconds >= 86400000 ? "".concat(time, " (Overnight)") : time;
      };

      var timeOptions = (0, _timeArray.default)(model.startOfDay || DEFAULT_START_OF_DAY, TIME_OPTION_INTERVAL_MINUTES).map(function (ms) {
        return {
          name: formatTime(ms),
          value: ms
        };
      });

      var beginTimeOptions = _toConsumableArray(timeOptions);

      beginTimeOptions.pop();

      var endTimeOptions = _toConsumableArray(timeOptions);

      endTimeOptions.shift();
      var hiddenTimeOptions = [];
      model.dynamicPrice.schedules.forEach(function (schedule) {
        if (!beginTimeOptions.find(function (_ref4) {
          var value = _ref4.value;
          return value === schedule.beginTimeMilliseconds;
        })) {
          Ember.set(schedule, 'invalidBeginTime', true);
          hiddenTimeOptions.push({
            name: formatTime(schedule.beginTimeMilliseconds),
            value: schedule.beginTimeMilliseconds
          });
        }

        if (!endTimeOptions.find(function (_ref5) {
          var value = _ref5.value;
          return value === schedule.endTimeMilliseconds;
        })) {
          Ember.set(schedule, 'invalidEndTime', true);
          hiddenTimeOptions.push({
            name: formatTime(schedule.endTimeMilliseconds),
            value: schedule.endTimeMilliseconds
          });
        }
      });
      controller.setProperties({
        beginTimeOptions: beginTimeOptions,
        endTimeOptions: endTimeOptions,
        hiddenTimeOptions: hiddenTimeOptions,
        isAnyScheduleOverlapping: false
      });
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'Edit',
        'Entry ID': model.dynamicPrice.guid,
        'Menu Component': 'Dynamic Pricing'
      });
    }
  });

  _exports.default = _default;
});