define("lh4/controllers/authenticated/locations/location/hospitality/choices-and-choice-sets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROUTE_PREFIX = 'authenticated.locations.location.hospitality.choices-and-choice-sets.set';

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    selectedChoiceSetsGuid: null,
    selectedChoiceSet: Ember.computed('selectedChoiceSetsGuid', function getter() {
      return this.store.peekRecord('hospitality/choice-set', this.get('selectedChoiceSetsGuid'));
    }),
    choices: Ember.computed('selectedChoiceSetsGuid', function getter() {
      return this.store.peekAll('hospitality/choice');
    }),
    showInactiveChoices: true,
    choiceSets: Ember.computed.filter('model.choiceSets', function filterCallback(set) {
      return set.get('locationId') === parseInt(this.get('model.location.id'), 10);
    }),
    isCreateChoiceButtonActive: true,
    actions: {
      createChoiceSets: function createChoiceSets() {
        this.transitionToRoute("".concat(ROUTE_PREFIX, ".create-set"));
        this.set('isCreateChoiceButtonActive', false);
      },
      createChoices: function createChoices() {
        if (this.isCreateChoiceButtonActive) {
          this.transitionToRoute("".concat(ROUTE_PREFIX, ".edit-set.choice.create-choice"));
        }
      },
      showCreateChoiceButton: function showCreateChoiceButton() {
        this.set('isCreateChoiceButtonActive', true);
      }
    }
  });

  _exports.default = _default;
});