define("lh4/utils/launch-control/employees", ["exports", "ember-uuid", "lh4/constants/launch-control/modules/employees"], function (_exports, _emberUuid, _employees) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addDefaultEmployeesToStepData = addDefaultEmployeesToStepData;
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function addDefaultEmployeesToStepData(stepData, locationId) {
    var changed = false;
    var data = JSON.parse(JSON.stringify(stepData || {}));
    data.jobs = data.jobs || {};
    data.employees = data.employees || {};
    data.jobs.records = data.jobs.records || [];
    data.jobs.removedDefaultRecords = data.jobs.removedDefaultRecords || [];
    data.employees.records = data.employees.records || [];
    data.employees.removedDefaultRecords = data.employees.removedDefaultRecords || [];

    _employees.DEFAULT_JOBS.forEach(function (defaultJob) {
      var record = data.jobs.records.find(function (job) {
        return job.name === defaultJob.name;
      });
      var removedRecord = data.jobs.removedDefaultRecords.find(function (job) {
        return job.name === defaultJob.name;
      });

      if (!record && !removedRecord) {
        changed = true;
        data.jobs.records = [].concat(_toConsumableArray(data.jobs.records), [_objectSpread(_objectSpread({}, defaultJob), {}, {
          locationId: locationId,
          guid: (0, _emberUuid.v4)(),
          _isDefault: true
        })]);
      }
    });

    _employees.DEFAULT_EMPLOYEES.forEach(function (defaultEmployee) {
      var record = data.employees.records.find(function (employee) {
        return employee.name === defaultEmployee.name;
      });
      var removedRecord = data.employees.removedDefaultRecords.find(function (employee) {
        return employee.name === defaultEmployee.name;
      });

      if (!record && !removedRecord) {
        changed = true;
        var job = data.jobs.records.find(function (it) {
          return it.name === defaultEmployee._job;
        });
        var guid = (0, _emberUuid.v4)();

        var _job = defaultEmployee._job,
            defaultEmployeeData = _objectWithoutProperties(defaultEmployee, ["_job"]);

        data.employees.records = [].concat(_toConsumableArray(data.employees.records), [_objectSpread(_objectSpread({}, defaultEmployeeData), {}, {
          locationId: locationId,
          guid: guid,
          jobIds: [job.guid],
          _payRates: [{
            guid: (0, _emberUuid.v4)(),
            locationId: locationId,
            employee: guid,
            employeeGuid: null,
            jobGuid: job.guid,
            payRate: job.payRate,
            isVoided: false
          }],
          _dates: [],
          _phones: [],
          _isDefault: true
        })]);
      }
    });

    return {
      changed: changed,
      stepData: data
    };
  }

  var _default = {
    addDefaultEmployeesToStepData: addDefaultEmployeesToStepData
  };
  _exports.default = _default;
});