define("lh4/templates/components/ui/form-fields/group-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MX7HfVfs",
    "block": "{\"symbols\":[\"record\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"records\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"group-field\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui form padded stackable grid\"],[8],[0,\"\\n\\t\\t\\t\"],[14,3,[[23,1,[]],[23,2,[]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",\"group-remove-icon\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"deleteRecord\"]]],null],[23,1,[]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui times circle grey icon\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"overlapErrorMessage\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted red segment error-message\"],[10,\"data-test-message\",\"error\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui icon exclamation triangle\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[1,[23,1,[\"overlapErrorMessage\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"buttonLabel\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",false],[12,\"tabindex\",\"0\"],[12,\"class\",\"add-record\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"addRecord\"]]],null]]],[8],[1,[22,\"buttonLabel\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/group-field.hbs"
    }
  });

  _exports.default = _default;
});