define("lh4/components/dropdown-checklist", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      // if there's only one location, select it now
      if (this.get('locations.length') === 1) {
        this.get('selected').addObject(this.get('locations.firstObject.id'));
      }

      if (!this.get('initialSelected')) {
        this.set('initialSelected', this.get('selected').toArray());
      }
    },
    searchTerm: null,
    locationsObject: Ember.computed('locations', 'searchTerm', 'selected.[]', function getter() {
      var _this = this;

      var _obj = [];
      var locations = this.get('locations');
      var searchTerm = this.get('searchTerm');

      if (searchTerm) {
        // I tried using regexp and .search() but Kevin yelled at me.
        locations = locations.filter(function (location) {
          return location.get('name').toUpperCase().indexOf(searchTerm.toUpperCase()) !== -1;
        });
      }

      if (locations) {
        locations.forEach(function (location) {
          _obj.push({
            id: location.get('id'),
            name: "".concat(location.get('name')),
            isChecked: _this.get('selected').includes(location.get('id')),
            merchantId: location.get('merchantId'),
            timezoneAbbr: location.get('timezone') ? _moment.default.tz(location.get('eproTimezone') || location.get('timezone')).format('z') : 'UTC',
            endOfDay: location.get('endOfDay')
          });
        });
      }

      return _obj;
    }),
    firstSelected: Ember.computed('locationsObject.[]', function getter() {
      return this.get('locationsObject').find(function (o) {
        return o.isChecked;
      });
    }),
    check: Ember.observer('locationsObject.@each.isChecked', 'selected', function callback() {
      var selected = this.get('selected');
      this.get('locationsObject').forEach(function (_location) {
        if (_location.isChecked) {
          selected.addObject(_location.id);
        } else {
          selected.removeObject(_location.id);
        }
      });
      this.set('firstSelected', this.get('locationsObject').find(function (o) {
        return o.isChecked;
      }));
    }),
    actions: {
      locationSearch: function locationSearch(term) {
        this.set('searchTerm', term);
      },
      checkAll: function checkAll() {
        var _this2 = this;

        // push ids of visible locations into selected
        this.get('locationsObject').forEach(function (location) {
          _this2.get('selected').addObject(location.id);
        });
      },
      clear: function clear() {
        this.set('searchTerm', null);
        this.set('selected', Ember.A([]));
      },
      showDropdown: function showDropdown() {
        if (!this.$('.dropdown-label').hasClass('visible')) {
          this.set('initialSelected', this.get('selected').toArray());
        }

        this.$('.dropdown-label').toggleClass('visible');
        this.$('.checklist__list').transition('fade down');
      },
      cancel: function cancel() {
        this.send('showDropdown');
        this.set('selected', this.get('initialSelected').toArray());
      },
      confirm: function confirm() {
        this.send('showDropdown');
      }
    }
  });

  _exports.default = _default;
});