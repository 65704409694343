define("lh4/models/loyalty/discount", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "ember-data/relationships", "lh4/utils/is-positive-decimal-of-length", "lh4/utils/is-positive-percentage"], function (_exports, _model, _attr, _emberCpValidations, _relationships, _isPositiveDecimalOfLength, _isPositivePercentage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LARGEST_INT4_VALUE = void 0;
  // Postgresql int4
  var LARGEST_INT4_VALUE = 2147483647;
  _exports.LARGEST_INT4_VALUE = LARGEST_INT4_VALUE;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Loyalty Discount Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    type: {
      description: 'Discount Type',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    value: {
      description: 'Value',
      validators: [(0, _emberCpValidations.validator)('presence', true), // The following is a list of the actual value of each mode:
      // Mode 'amount' (0) = Amount Off($) with a maximum of 10 digits with a 2 digit precision
      // Mode 'percent' (1) = Percentage Off(%) can't be less than 0 & over 100.00
      (0, _emberCpValidations.validator)(function (value, options, model) {
        return model.get('type') === 'percent' ? (0, _isPositivePercentage.default)(value) : (0, _isPositiveDecimalOfLength.default)(value, 10);
      }, {
        dependentKeys: ['model.type']
      })]
    },
    pointCost: {
      description: 'Point Cost',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        positive: true,
        allowBlank: false,
        allowString: true,
        lte: LARGEST_INT4_VALUE
      })]
    },
    loyaltyProgramGuid: {
      description: 'Loyalty Program',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    loyaltyProgramGuid: (0, _relationships.belongsTo)('loyalty/program'),
    // enum: 'percent' || 'amount'
    type: (0, _attr.default)('string', {
      defaultValue: 'amount'
    }),
    name: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    value: (0, _attr.default)('string'),
    pointCost: (0, _attr.default)('number')
  });

  _exports.default = _default;
});