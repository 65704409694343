define("lh4/controllers/authenticated/customer-database/create", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _generalFormController.default.extend({
    validateAndSaveChangesets: function validateAndSaveChangesets(changesets, key) {
      var _this = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (!record.isValid) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.set('customer', _this.model.customer); // record.save was throwing because models don't have a save endpoint

          record.execute();
          record.rollback();

          _this.model.customer.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    areChildrenPristine: function areChildrenPristine(model, changeset) {
      // check for any records added or removed from the array
      if (model.filter(function (record) {
        return !record.isNew;
      }).length !== changeset.length) {
        return false;
      }

      return changeset.every(function (c) {
        return c.isPristine;
      });
    },
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    closeModal: function closeModal() {
      var _this2 = this;

      Ember.run.later(function () {
        _this2.transitionToRoute('authenticated.customer-database');
      }, 1500);
      this.set('showErrors', false);
    },
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.customer-database');
      },
      create: function create() {
        var _this3 = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.customer.phones, this.changeset.phoneChangesets) && this.areChildrenPristine(this.model.customer.emails, this.changeset.emailChangesets) && this.areChildrenPristine(this.model.customer.addresses, this.changeset.addressChangesets); // validating and saving at the same time for convience.
        // If this causes a problem it should be moved into the same block for model.

        var phonePromises = this.validateAndSaveChangesets(this.changeset.phoneChangesets, 'phones');
        var emailPromises = this.validateAndSaveChangesets(this.changeset.emailChangesets, 'emails');
        var addressPromises = this.validateAndSaveChangesets(this.changeset.addressChangesets, 'addresses');
        Ember.RSVP.all([].concat(_toConsumableArray(phonePromises), _toConsumableArray(emailPromises), _toConsumableArray(addressPromises))).then(function (values) {
          var areChildrenValid = values.every(function (value) {
            return value;
          });

          _this3.changeset.validate().then(function () {
            if (!(_this3.changeset.get('isValid') && areChildrenValid)) {
              return Ember.RSVP.reject(_this3.changeset.get('errors'));
            }

            _this3.removeRecordsFromModel(_this3.model.customer.phones, _this3.changeset.phoneChangesets);

            _this3.removeRecordsFromModel(_this3.model.customer.emails, _this3.changeset.emailChangesets);

            _this3.removeRecordsFromModel(_this3.model.customer.addresses, _this3.changeset.addressChangesets);

            if (isPristine) {
              _this3.closeModal();

              return Ember.RSVP.resolve();
            }

            return _this3.changeset.save().then(function () {
              _this3.closeModal();

              return Ember.RSVP.resolve();
            }).catch(function (_ref2) {
              var errors = _ref2.errors;

              if (!(errors && errors[0])) {
                return;
              } // TODO normalize how we handle these error messages: LH-8461


              var createError = 'Something went wrong, customer could not be created.';
              errors.forEach(function (error) {
                var errorMessage = error.details && error.details[0] || error.message || createError;

                _this3.set('serverError', errorMessage);

                _this3.set('serverErrorTimeoutId', Ember.run.later(_this3, function () {
                  _this3.set('serverError', null);
                }, _this3.serverErrorTimeoutDelay));
              });
            });
          }).catch(function (errors) {
            // We currently don't have a field component for checkboxes
            // Because of this there is no standard way of displaying
            // error messages for boolean fields
            // https://jira.shift4payments.com/browse/LH-8853
            if (errors.length) {
              var booleanError = errors.find(function (error) {
                return typeof error.value === 'boolean';
              });

              if (booleanError) {
                _this3.set('booleanError', booleanError.validation);

                _this3.set('serverErrorTimeoutId', Ember.run.later(_this3, function () {
                  _this3.set('booleanError', null);
                }, _this3.serverErrorTimeoutDelay));
              }
            }
          }).finally(function () {
            return _this3.set('isSaving', false);
          });
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      }
    }
  });

  _exports.default = _default;
});