define("lh4/serializers/echo-pro/tax", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;
      var decimals = json.isFlatAmount ? 2 : 3;

      if (!json.isFlatAmount) {
        json.value *= 100;
      }

      json.value = Number.parseFloat(json.value).toFixed(decimals);

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var decimals = json.isFlatAmount ? 2 : 3;
      json.value = Number.parseFloat(json.value).toFixed(decimals);

      if (!json.isFlatAmount) {
        json.value /= 100;
      }

      return json;
    },
    attrs: {
      items: {
        embedded: 'always',
        serialize: 'id'
      },
      orderTypes: {
        embedded: 'always',
        serialize: 'id'
      }
    }
  });

  _exports.default = _default;
});