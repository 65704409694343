define("lh4/adapters/internet-payments/transaction", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), "/detail?locationId=").concat(this.location.model.id);
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      if (!response.transactions) {
        return response;
      }

      var transactions = response.transactions,
          count = response.count;
      return {
        transactions: transactions.map(function (transaction) {
          return _objectSpread(_objectSpread({}, transaction), {}, {
            serverName: transaction.serverName === null || transaction.serverName === '' ? 'Unspecified' : transaction.serverName
          });
        }),
        meta: {
          count: transactions.length ? count : 0
        }
      };
    }
  });

  _exports.default = _default;
});