define("lh4/routes/authenticated/epro/manage-employees/employees/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'employee',
    childChangesets: {
      employeeDates: 'dateChangesets',
      employeePhones: 'phoneChangesets',
      payRates: 'payRateChangesets'
    },
    model: function model(_ref) {
      var employeeId = _ref.employeeId;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        employee: this.store.findRecord('echo-pro/employee', employeeId, {
          reload: true
        }),
        permissions: this.store.query('echo-pro/permission', {
          location: location
        }),
        jobs: this.store.query('echo-pro/job', {
          location: location
        }),
        printers: this.store.query('echo-pro/printer', {
          location: location
        })
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // This is to create missing payrates that exist in the jobs array.
      var missingRecords = model.employee.jobs.filter(function (job) {
        return !model.employee.payRates.find(function (payRate) {
          return payRate.jobGuid === job.guid;
        });
      });

      if (missingRecords.length) {
        var createdRecords = missingRecords.map(function (job) {
          return _this.store.createRecord('echo-pro/pay-rate', {
            locationId: job.locationId,
            employeeGuid: model.employee.id,
            jobGuid: job.guid,
            payRate: job.payRate
          });
        });
        model.employee.payRates.pushObjects(createdRecords);
      } // This adjusts the payRate value to the jobs value if the
      // employee pay rate does not exist (which should not happen if labor tracking is enabled)


      model.employee.payRates.map(function (payRate) {
        var job = model.employee.jobs.findBy('id', payRate.jobGuid);

        if (job && payRate.payRate == null) {
          payRate.set('payRate', job.payRate);
        }

        return payRate;
      });
    }
  });

  _exports.default = _default;
});