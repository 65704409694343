define("lh4/utils/changeset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseServerError = parseServerError;
  _exports.default = void 0;

  /**
   * Parses server error and maps it to appropriate attribute.
   *
   * @param {string} serverError
   * @param {{ serverError: string; attribute: string; validation: string }[]} supporterErrors list of supporter errors
   * @param {Changeset} changeset Ember store changeset
   * @returns {boolean} was server error handled.
   */
  function parseServerError(serverError, supporterErrors, changeset) {
    var supporterError = supporterErrors.find(function (it) {
      return it.serverError === serverError;
    });

    if (supporterError) {
      changeset.addError(supporterError.attribute, supporterError.validation);
      return true;
    }

    return false;
  }

  var _default = parseServerError;
  _exports.default = _default;
});