define("lh4/controllers/authenticated/epro/manage-employees/employees/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _generalFormController.default.extend({
    canEditCardOnlyAccess: true,
    validateAndSaveChangesets: function validateAndSaveChangesets(changeset, key) {
      var _this = this;

      return changeset.map(function (record) {
        // This iterates through the pay rates changesets and compares job guid and pay rate value
        // To prevent duplicate pay rate values on the same job.
        if (key === 'payRates') {
          var duplicatePayrateValues = changeset.filter(function (payRate) {
            return payRate.get('jobGuid') === record.get('jobGuid');
          });

          if (duplicatePayrateValues.length > 1) {
            record.pushErrors('payRate', 'You cannot have multiple pay rates for the same job.');
            return false;
          }
        }

        return record.validate().then(function () {
          if (!record.isValid) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.set('employee', _this.model.employee); // record.save was throwing because models don't have a save endpoint

          record.execute();
          record.rollback();

          _this.model.employee.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    actions: {
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      disableCardOnlyAccessCheckbox: function disableCardOnlyAccessCheckbox() {
        this.set('canEditCardOnlyAccess', false);
        this.changeset.set('isCardOnlyAccess', false);
      },
      close: function close() {
        this.clearTemporaryData();
        this.transitionOnClose();
        this.setProperties({
          shouldCloseTray: false,
          isSaving: false,
          canEditCardOnlyAccess: true
        });
        this.clearServerError();
      },
      clone: function clone() {
        var id = this.changeset.get('id');
        this.transitionToRoute('authenticated.epro.manage-employees.employees.create', {
          queryParams: {
            clone: id
          }
        });
      },
      update: function update() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.employee.employeePhones, this.changeset.phoneChangesets) && this.areChildrenPristine(this.model.employee.employeeDates, this.changeset.dateChangesets) && this.areChildrenPristine(this.model.employee.payRates, this.changeset.payRateChangesets); // validating and saving at the same time for convenience.
        // If this causes a problem it should be moved into the same block for model.

        var phonePromises = this.validateAndSaveChangesets(this.changeset.phoneChangesets, 'employeePhones');
        var datePromises = this.validateAndSaveChangesets(this.changeset.dateChangesets, 'employeeDates');
        var payRatePromises = this.validateAndSaveChangesets(this.changeset.payRateChangesets, 'payRates');
        Ember.RSVP.all([].concat(_toConsumableArray(phonePromises), _toConsumableArray(datePromises), _toConsumableArray(payRatePromises))).then(function (values) {
          var areChildrenValid = values.every(function (value) {
            return value;
          });

          _this2.changeset.validate().then(function () {
            if (!(_this2.changeset.isValid && areChildrenValid)) {
              return Ember.RSVP.reject(_this2.changeset.get('errors'));
            }

            if (!_this2.changeset.payRateChangesets.length) {
              return Ember.RSVP.reject([{
                message: 'Employee must be assigned a job'
              }]);
            }

            _this2.removeRecordsFromModel(_this2.model.employee.employeePhones, _this2.changeset.phoneChangesets);

            _this2.removeRecordsFromModel(_this2.model.employee.employeeDates, _this2.changeset.dateChangesets);

            _this2.removeRecordsFromModel(_this2.model.employee.payRates, _this2.changeset.payRateChangesets);

            if (isPristine) {
              _this2.clearTemporaryData();

              _this2.closeTray();

              return Ember.RSVP.resolve();
            }

            var snapshot = _this2.changeset.snapshot();

            return _this2.changeset.save().then(function () {
              _this2.clearTemporaryData();

              _this2.closeTray();
            }).catch(function (_ref2) {
              var errors = _ref2.errors;

              _this2.changeset.restore(snapshot);

              _this2.changeset.data.rollbackAttributes();

              if (!(errors && errors[0])) {
                return;
              }

              throw errors;
            });
          }).catch(function (errors) {
            var createError = "Something went wrong, ".concat(_this2.instanceName, " could not be updated.");
            errors.forEach(function (error) {
              if (error.message.indexOf('Card Only Access') !== -1) {
                _this2.send('disableCardOnlyAccessCheckbox');
              }

              var errorMessage = error.details && error.details[0] || error.message || createError;
              return _this2.set('serverError', errorMessage);
            });
          }).finally(function () {
            return _this2.set('isSaving', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});