define("lh4/routes/authenticated/epro/menu/departments/edit", ["exports", "lh4/routes/general-edit-route", "moment", "ember-uuid", "lh4/utils/time/time-array"], function (_exports, _generalEditRoute, _moment, _emberUuid, _timeArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var DEFAULT_START_OF_DAY = '04:00 AM';
  var TIME_OPTION_INTERVAL_MINUTES = 15;

  var _default = _generalEditRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    intradayPeriod: Ember.inject.service('intraday-period'),
    locationSettings: Ember.inject.service('echo-pro/settings'),
    modelName: 'department',
    // overriding hasDirtyChangesets function set by ChangesetTransitionRouteMixin
    // for custom isDirty check before closing modal
    // because we have unusual situation nested changesets
    hasDirtyChangesets: function hasDirtyChangesets() {
      var changeset = this.controller.changeset;
      return !!(changeset.isDirty || changeset.dynamicPricingChangesets.some(function (childChangeset) {
        return childChangeset.isDirty;
      }) || changeset.availabilityChangeset.isDirty || changeset.availabilityChangeset.scheduleChangesets.some(function (childChangeset) {
        return childChangeset.isDirty;
      }));
    },
    model: function model(_ref) {
      var departmentId = _ref.departmentId;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        department: this.store.findRecord('echo-pro/department', departmentId),
        startOfDay: this.locationSettings.getEoDSetting(location),
        items: this.modelFor('authenticated.epro.menu.departments').items,
        courses: this.modelFor('authenticated.epro.menu.departments').courses,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null,
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: location
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref2) {
            var guid = _ref2.guid,
                revenueCenterName = _ref2.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        dynamicPrices: this.store.query('echo-pro/availability-price/dynamic-price', {
          location: location,
          limit: 100,
          order: 'isActive',
          sort: 'desc'
        }),
        dayPartOptions: this.intradayPeriod.getDayPartsData(this.controller, location),
        intradayPeriodOptions: []
      });
    },
    afterModel: function afterModel(model) {
      model.dayPartOptions.dayParts.intradayPeriods.forEach(function (_ref3) {
        var periods = _ref3.periods;
        periods.forEach(function (_ref4) {
          var groupGuid = _ref4.groupGuid,
              name = _ref4.name;
          model.intradayPeriodOptions.push({
            value: groupGuid,
            name: name
          });
        });
      });
    },
    setupController: function setupController(theController, model) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [theController, model].concat(args));

      var controller = theController;
      controller.set('isAnyScheduleOverlapping', false);
      controller.changeset.dynamicPricingChangesets = model.department.dynamicPricing.map(this.buildChildChangeset);
      var availability = !model.department.belongsTo('availability').value() ? this.store.createRecord('echo-pro/availability-price/availability', {
        locationId: this.location.model.id,
        scheduleTitle: (0, _emberUuid.v4)(),
        isActive: true,
        timeOption: 'All Day',
        revenueCenterGuids: [],
        intradayPeriodGroupGuids: [],
        schedules: [],
        overrideDates: [],
        isDateOverrideEnabled: false
      }) : model.department.availability.content;
      controller.changeset.availabilityChangeset = this.buildChildChangeset(availability);
      controller.changeset.availabilityChangeset.scheduleChangesets = availability.schedules.map(this.buildChildChangeset);

      var formatTime = function formatTime(milliseconds) {
        var time = _moment.default.utc(milliseconds).format('h:mm A');

        return milliseconds >= 86400000 ? "".concat(time, " (Overnight)") : time;
      };

      var timeOptions = (0, _timeArray.default)(model.startOfDay || DEFAULT_START_OF_DAY, TIME_OPTION_INTERVAL_MINUTES).map(function (ms) {
        return {
          name: formatTime(ms),
          value: ms
        };
      });

      var beginTimeOptions = _toConsumableArray(timeOptions);

      beginTimeOptions.pop();

      var endTimeOptions = _toConsumableArray(timeOptions);

      endTimeOptions.shift();
      var hiddenTimeOptions = [];
      availability.schedules.forEach(function (schedule) {
        if (!beginTimeOptions.find(function (_ref5) {
          var value = _ref5.value;
          return value === schedule.beginTimeMilliseconds;
        })) {
          Ember.set(schedule, 'invalidBeginTime', true);
          hiddenTimeOptions.push({
            name: formatTime(schedule.beginTimeMilliseconds),
            value: schedule.beginTimeMilliseconds
          });
        }

        if (!endTimeOptions.find(function (_ref6) {
          var value = _ref6.value;
          return value === schedule.endTimeMilliseconds;
        })) {
          Ember.set(schedule, 'invalidEndTime', true);
          hiddenTimeOptions.push({
            name: formatTime(schedule.endTimeMilliseconds),
            value: schedule.endTimeMilliseconds
          });
        }
      });
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'Edit',
        'Entry ID': model.department.guid,
        'Menu Component': 'Department'
      });
      var activeDynamicPrice = model.dynamicPrices.find(function (x) {
        return x.isActive;
      });
      controller.setProperties({
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        },
        firstDynamicPriceGuid: activeDynamicPrice === null || activeDynamicPrice === void 0 ? void 0 : activeDynamicPrice.guid,
        beginTimeOptions: beginTimeOptions,
        endTimeOptions: endTimeOptions,
        hiddenTimeOptions: hiddenTimeOptions
      });
    }
  });

  _exports.default = _default;
});