define("lh4/models/echo-pro/tax", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/has-many-ids", "lh4/utils/is-positive-decimal-or-zero", "lh4/utils/is-positive-decimal", "lh4/utils/is-positive-percentage-or-zero"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _modelCloneable, _hasManyIds, _isPositiveDecimalOrZero, _isPositiveDecimal, _isPositivePercentageOrZero) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Tax name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100,
        description: 'Tax name length'
      })]
    },
    value: {
      description: 'Value',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (parseFloat(value) < 0) {
          return 'Value cannot be less than zero';
        }

        if (parseFloat(value) > 0 && model.get('isFlatAmount') && !(0, _isPositiveDecimal.default)(value)) {
          return 'Value can only contain two decimal numbers';
        }

        if (parseFloat(value) > 0 && !(0, _isPositiveDecimal.default)(value, 3)) {
          return 'Value can only contain three decimal numbers';
        }

        return model.get('isFlatAmount') ? (0, _isPositiveDecimalOrZero.default)(value) : (0, _isPositivePercentageOrZero.default)(value, 3);
      }, {
        dependentKeys: ['isFlatAmount']
      })]
    }
  });

  var _default = _model.default.extend(Validations, _modelCloneable.default, {
    corporation: Ember.inject.service(),
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),
    defaultTax: (0, _attr.default)('boolean'),
    isFlatAmount: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isNotAppliedIfAllLiquor: (0, _attr.default)('boolean'),
    isHiddenIfAllLiquor: (0, _attr.default)('boolean'),
    hidden: (0, _attr.default)('boolean'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    items: (0, _relationships.hasMany)('echo-pro/item'),
    itemIds: Ember.computed('items', (0, _hasManyIds.default)('items', 'echo-pro/item')),
    orderTypes: (0, _relationships.hasMany)('echo-pro/order-type'),
    orderTypeIds: Ember.computed('orderTypes', (0, _hasManyIds.default)('orderTypes', 'echo-pro/order-type')),
    printSeparate: (0, _attr.default)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});