define("lh4/templates/components/internet-payments/modals/confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Ho5OqVV",
    "block": "{\"symbols\":[\"@onClose\",\"@onProceed\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,0,[\"titleName\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"right\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",\"big times icon\"],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,1,[]]],null]]],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"headerName\"],[8],[1,[23,0,[\"headerName\"]],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[1,[28,\"breakline\",[[23,0,[\"contentText\"]]],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"footer\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui button cancel\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,1,[]]],null]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,0,[\"cancelText\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary button delete\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,2,[]]],null]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,0,[\"proceedText\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/modals/confirmation-modal.hbs"
    }
  });

  _exports.default = _default;
});