define("lh4/mirage/routes/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageLocationsServer;

  function setMirageLocationsServer(server) {
    server.get('/locations');
    server.get('/locations/:locationId', function (_ref, request) {
      var locations = _ref.locations;
      var id = request.params.locationId;
      return locations.find(id);
    });
    server.get('/locations/overview/:locationId', function (_ref2, request) {
      var overviews = _ref2.overviews;
      var merchantId = request.params.locationId;
      return overviews.find(merchantId);
    });
    server.get('/statements');
  }
});