define("lh4/models/echo-pro/modifier-set", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/boolean-property-array", "lh4/utils/has-many-ids", "lh4/utils/is-positive-decimal-or-zero"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _modelCloneable, _booleanPropertyArray, _hasManyIds, _isPositiveDecimalOrZero) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Modifier Set Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    kitchenPrintName: {
      description: 'Kitchen Print Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    isFlatPrice: {
      description: 'Flat Price',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      })]
    },
    value: {
      description: 'Value',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)(function (value) {
        return (0, _isPositiveDecimalOrZero.default)(value) && String(value).length <= 9 || 'Value must be a positive number, can only contain maximum two decimal numbers and may only be 9 characters long';
      }, {
        dependentKeys: ['isFlatPrice']
      })]
    },
    onlineName: {
      description: 'Online Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    onlineDescription: {
      description: 'Online Description',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    minModifiersPerSet: {
      description: 'Minimum amount of modifiers',
      disabled: Ember.computed.not('model.isRequired'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0
      })]
    },
    maxModifiersPerSet: {
      description: 'Maximum amount of modifiers',
      disabled: Ember.computed.alias('model.oneOnly'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0,
        gte: Ember.computed('model.minModifiersPerSet', 'model.isRequired', function getter() {
          if (this.model.get('isRequired')) {
            return this.model.get('minModifiersPerSet');
          }

          return undefined;
        })
      })]
    },
    maxQuantityForIndividualModifier: {
      description: 'Maximum quantity for individual modifier',
      disabled: Ember.computed.not('model.areModifierQuantitiesEnabled'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0,
        lte: Ember.computed('model.maxModifiersPerSet', 'model.oneOnly', function getter() {
          if (!this.model.get('oneOnly')) {
            return this.model.get('maxModifiersPerSet');
          }

          return undefined;
        })
      })]
    }
  });
  var OPTIONS = {
    isPrintedRedOrBold: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isSetNamePrepended: (0, _attr.default)('boolean', {
      defaultValue: false
    })
  };

  var _default = _model.default.extend(Validations, _modelCloneable.default, OPTIONS, {
    guid: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    kitchenPrintName: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isFlatPrice: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    isRequired: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    areModifierQuantitiesEnabled: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    areOptionsAutoDisplayed: (0, _attr.default)('boolean'),
    oneOnly: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    locationId: (0, _attr.default)('number', {
      isCopyable: true
    }),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    value: (0, _attr.default)('number', {
      isCopyable: true
    }),
    createdAt: (0, _attr.default)('string'),
    onlineActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    onlineName: (0, _attr.default)('string'),
    onlineDescription: (0, _attr.default)('string', {
      isCopyable: true
    }),
    minQuantityForIndividualModifier: (0, _attr.default)('number', {
      defaultValue: 1,
      isCopyable: true
    }),
    maxQuantityForIndividualModifier: (0, _attr.default)('number', {
      defaultValue: 5,
      isCopyable: true
    }),
    minModifiersPerSet: (0, _attr.default)('number', {
      defaultValue: 1,
      isCopyable: true
    }),
    maxModifiersPerSet: (0, _attr.default)('number', {
      defaultValue: 20,
      isCopyable: true
    }),
    modifiers: (0, _relationships.hasMany)('echo-pro/modifier'),
    modifiersMap: (0, _relationships.hasMany)('echo-pro/modifier-set-map'),
    nestedModifiers: (0, _relationships.hasMany)('echo-pro/modifier', {
      inverse: 'nestedModifierSets'
    }),
    items: (0, _relationships.hasMany)('echo-pro/item'),
    itemIds: Ember.computed('items', (0, _hasManyIds.default)('items', 'echo-pro/item')),
    options: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(OPTIONS)).concat([(0, _booleanPropertyArray.default)(Object.keys(OPTIONS))]))
  });

  _exports.default = _default;
});