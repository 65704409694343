define("lh4/routes/authenticated/locations/location/business-settings", ["exports", "moment-timezone", "lh4/mixins/authenticated-route-mixin", "lh4/utils/weekdays"], function (_exports, _momentTimezone, _authenticatedRouteMixin, _weekdays) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    store: Ember.inject.service(),
    settings: Ember.inject.service('echo-pro/settings'),
    businessSettingsService: Ember.inject.service('business-settings'),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var locationController = this.controllerFor('authenticated.locations.location');
      locationController.set('showLocationTabs', true);
      controller.setProperties({
        timezoneAbbreviation: _momentTimezone.default.tz(model.businessSettings.timezone).format('z'),
        gatewayEodTime: model.businessSettings.gatewayEodTime,
        depositFundingTime: model.businessSettings.depositFundingTime,
        skyTabPosEodTime: model.businessSettings.skyTabPosEodTime,
        skyTabPosPayPeriod: model.skyTabPosPayPeriod
      });
    },
    beforeModel: function beforeModel() {
      if (this.can.cannot('view business-settings in business-setting')) {
        this.transitionTo('/');
      }
    },
    model: function model() {
      var locationId = this.location.model.id;
      return Ember.RSVP.hash({
        businessSettings: this.businessSettingsService.getBusinessSettings(locationId),
        skyTabPosPayPeriod: this.store.queryRecord('echo-pro/setting', {
          location: locationId,
          type: ['business']
        }).then(function (record) {
          var weekdays = (0, _weekdays.default)(record.business.get('startOfWeek'));
          return "".concat(weekdays[0], " through ").concat(weekdays[weekdays.length - 1]);
        })
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var locationController = this.controllerFor('authenticated.locations.location');
        locationController.set('showLocationTabs', false);
      }
    }
  });

  _exports.default = _default;
});