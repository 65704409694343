define("lh4/components/table-layout", ["exports", "lh4/components/table-layout/shared/element-styles", "lh4/components/table-layout/shared/constants"], function (_exports, _elementStyles, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var refreshSectionTables = function refreshSectionTables(context) {
    var currentSectionGuid = context.get('currentSection.id');
    var sectionTables = context.get('tables').filter(function (table) {
      var tableSectionId = table.get('sectionGuid.id') || table.get('sectionId');
      var hasSameSection = tableSectionId === currentSectionGuid;
      var passesActiveCheck = context.get('activeCheck') ? true : table.get('isActive');
      return hasSameSection && passesActiveCheck;
    });
    context.set('sectionTables', sectionTables);
    context.send('clearCanvas', true); // context.insertStartingTables();

    context.get('sectionTables').forEach(function (table) {
      var tableData = table.toJSON({
        includeId: true
      });
      tableData.guid = table.get('guid');
      context.send('insertExistingRecord', tableData, table.get('shape') !== _constants.SHAPE_LABEL);
    });
  };

  var updatedBoundaries = function updatedBoundaries(obj, canvas) {
    /*  eslint-disable no-param-reassign */
    obj.setCoords();
    var rect = obj.getBoundingRect(); // top-left corner

    if (rect.top < 0 || rect.left < 0) {
      obj.top = Math.max(obj.top, obj.top - rect.top);
      obj.left = Math.max(obj.left, obj.left - rect.left);
    } // bottom-right corner


    if (rect.top + rect.height > canvas.height || rect.left + rect.width > canvas.width) {
      obj.top = Math.min(obj.top, canvas.height - rect.height + obj.top - rect.top);
      obj.left = Math.min(obj.left, canvas.width - rect.width + obj.left - rect.left);
    }
    /* eslint-enable no-param-reassign */

  }; // deprecated component


  var _default = Ember.Component.extend({
    sections: [],
    currentSection: null,
    newSection: null,
    activeCheck: false,
    canvas: null,
    tables: [],
    sectionTables: [],
    currentSelection: null,
    nextTableNumber: 1,
    nameTimeout: null,
    currentSelectionIsTable: Ember.computed('currentSelection', function getter() {
      return this.get('currentSelection').meta.type === 'table';
    }),
    currentSelectionIsLabel: Ember.computed('currentSelection', function getter() {
      return this.get('currentSelection').meta.type === _constants.SHAPE_LABEL;
    }),
    tableNameChanged: Ember.observer('currentSelection.meta.name', function callback() {
      var _this = this;

      if (this.get('currentSelection')) {
        if (this.get('nameTimeout')) {
          clearTimeout(this.get('nameTimeout'));
        }

        this.set('nameTimeout', setTimeout(function () {
          var currentSelection = _this.get('currentSelection');

          if (currentSelection && currentSelection.meta.name !== currentSelection.getObjects().filter(function (x) {
            return x.isType('text');
          }).pop().get('text')) {
            _this.send('renameItem', currentSelection.meta.name);
          }
        }, 750));
      }
    }),
    setActive: Ember.observer('currentSelection.meta.isActive', function callback() {
      var currentSelection = this.get('currentSelection');

      if (currentSelection) {
        this.sendAction('updateTableProps', currentSelection.meta.guid, {
          isActive: currentSelection.meta.isActive
        });

        if (!currentSelection.meta.isActive && !this.get('activeCheck')) {
          this.get('canvas').remove(currentSelection);
          this.get('canvas').renderAll();
          this.set('currentSelection', null);
        }
      }
    }),
    showHideActive: Ember.observer('activeCheck', function callback() {
      this.send('clearCanvas', true);
      refreshSectionTables(this);
    }),
    loadingStateChanged: Ember.observer('isLoading', function callback() {
      if (!this.get('isLoading')) {
        // Records might have been updated after save
        this.send('clearCanvas', true);
        refreshSectionTables(this);
      }
    }),
    // when new record is added, insert it into the canvas
    // observer is necessary because addedRecord won't change inside of the action
    // done like this so that the id can be returned by the api before being inserted
    addedNewItem: Ember.observer('addedRecord', function callback() {
      var itemData = this.get('addedRecord').toJSON({
        includeId: true
      });
      itemData.guid = this.get('addedRecord.guid');
      this.send('insertExistingRecord', itemData, this.get('addedRecord.shape') !== _constants.SHAPE_LABEL);
    }),
    addedNewSection: Ember.observer('addedSection', function callback() {
      this.send('selectSection', this.get('addedSection'));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('sections').length !== 0) {
        this.set('currentSection', this.get('sections')[0]);
      } // No clue why this is required but must delay
      // the creation of the Fabric canvas for a bit for it to work
      // I think we have to wait for the DOM to load
      // afterRender should be more reliable in case it takes too long to load


      Ember.run.schedule('afterRender', this, function renderCallback() {
        var _this2 = this;

        this.set('canvas', new fabric.Canvas('table-layout')); // do not let to move objects outside of the canvas boundaries

        this.get('canvas').on('object:moving', function (e) {
          updatedBoundaries(e.target, _this2.get('canvas'));
        });
        this.get('canvas').setHeight(690);
        this.get('canvas').setWidth(920);
        this.get('canvas').selection = false; // Set the background image

        this.get('canvas').setBackgroundImage('/img/layout-grid.png', this.get('canvas').renderAll.bind(this.get('canvas')), {
          originX: 'left',
          originY: 'top'
        }); // If we've got any tables in the model we'll have to
        // insert them all now

        refreshSectionTables(this); // Watch for element selections

        this.get('canvas').on('object:selected', function (e) {
          _this2.get('canvas').getObjects().filter(function (group) {
            return group.isType('group') && group.meta.type !== _constants.SHAPE_LABEL;
          }).forEach(function (group) {
            group.getObjects().forEach(function (x) {
              if (!x.isType('text')) {
                x.set('stroke', '#F1F1F1');
                x.set('strokeWidth', 0);
              } else {
                x.set('fill', 'black');
              }
            });
          });

          if (e.target.meta.type !== _constants.SHAPE_LABEL) {
            e.target.getObjects().forEach(function (x) {
              if (typeof x.setText === 'function') {
                x.set('fill', _elementStyles.htBlue);
              } else {
                x.set('stroke', _elementStyles.htBlue);
                x.set('strokeWidth', 4);
              }
            });
          }

          _this2.send('objectSelected');
        });
        this.get('canvas').on('object:modified', function (e) {
          e.target.getObjects().filter(function (x) {
            return !x.isType('text');
          }).forEach(function (shape) {
            _this2.sendAction('updateTableProps', _this2.get('currentSelection').meta.guid, {
              posX: shape.group.get('left'),
              posY: shape.group.get('top')
            });
          });
        });
        this.get('canvas').on('selection:cleared', function () {
          if (_this2.get('currentSelection').meta.type === 'table') {
            _this2.get('currentSelection').getObjects().forEach(function (x) {
              if (typeof x.setText === 'function') {
                x.set('fill', 'black');
              } else {
                x.set('stroke', '#F1F1F1');
                x.set('strokeWidth', 0);
              }
            });
          }

          _this2.set('currentSelection', null);
        });
      });
    },
    actions: {
      saveChanges: function saveChanges() {
        this.sendAction('saveChanges');
      },
      discardChanges: function discardChanges() {
        this.sendAction('discardChanges');
      },
      updateTableProps: function updateTableProps(guid, props) {
        this.sendAction('updateTableProps', guid, props);
      },
      createRecord: function createRecord(type, record) {
        this.sendAction('createRecord', type, record);
      },
      selectSection: function selectSection(selectedSection) {
        this.set('currentSection', selectedSection);
        refreshSectionTables(this);
      },
      tableShapeChanged: function tableShapeChanged() {
        var _this3 = this;

        var id = this.get('currentSelection').meta.guid;
        this.get('canvas').remove(this.get('currentSelection'));
        var existingRecord = this.get('tables').filter(function (table) {
          return table.get('guid') === id;
        }).pop();
        var existingRecordData = existingRecord.toJSON({
          includeId: true
        });
        existingRecordData.guid = existingRecord.get('guid');
        this.send('insertExistingRecord', existingRecordData, false);
        Ember.run.next(function () {
          _this3.get('canvas').setActiveObject(_this3.get('canvas').getObjects().filter(function (table) {
            return table.meta.guid === id;
          }).pop());

          _this3.get('canvas').renderAll();
        });
      },
      elementSectionChanged: function elementSectionChanged(id, sectionValue) {
        if (this.get('currentSelection') && this.get('currentSection.id') !== sectionValue.id) {
          this.sendAction('elementSectionChanged', this.get('currentSelection').meta.guid, sectionValue);
          this.get('canvas').remove(this.get('currentSelection'));
          this.get('canvas').renderAll();
        }
      },
      insertExistingRecord: function insertExistingRecord(table, increment) {
        var group;

        if (table.shape === _constants.TABLE_SHAPE_ROUND) {
          var shape = new fabric.Circle(_elementStyles.circleDefaults);
          shape.set('radius', table.width / 2);
          var label = new fabric.Text(table.name, _elementStyles.textDefaults);
          group = new fabric.Group([shape, label], _elementStyles.groupDefaults);
          group.set('left', table.posX);
          group.set('top', table.posY);
          group.set('radius', table.width / 2);
          group.meta = {
            id: table.id,
            guid: table.guid,
            isActive: table.isActive,
            maxOccupants: table.maxOccupants,
            angle: 0,
            type: 'table',
            name: table.name
          };
        } else if (table.shape === _constants.TABLE_SHAPE_RECT) {
          var _shape = new fabric.Rect(_elementStyles.rectangleDefaults);

          _shape.set('width', table.width);

          _shape.set('height', table.height);

          var _label = new fabric.Text(table.name, _elementStyles.textDefaults);

          group = new fabric.Group([_shape, _label], _elementStyles.groupDefaults);
          group.set('left', table.posX);
          group.set('top', table.posY);
          group.set('width', table.width);
          group.set('height', table.height);
          group.set('angle', table.rotation);
          group.meta = {
            id: table.id,
            guid: table.guid,
            isActive: table.isActive,
            maxOccupants: table.maxOccupants,
            angle: table.rotation,
            type: 'table',
            name: table.name
          };
        } else if (table.shape === _constants.SHAPE_LABEL) {
          var text = table.name;

          var _label2 = new fabric.Text(text, _elementStyles.labelDefaults);

          var background = new fabric.Rect({
            width: table.width,
            height: table.height,
            fill: _elementStyles.htBlue,
            originX: 'center',
            originY: 'center',
            rx: 2.5,
            ry: 2.5,
            lockScalingX: true,
            lockScalingY: true,
            hasControls: false,
            hasBorders: false
          });
          group = new fabric.Group([background, _label2], _elementStyles.groupDefaults);
          group.set('left', table.posX);
          group.set('top', table.posY);
          group.set('angle', table.rotation);
          group.meta = {
            type: _constants.SHAPE_LABEL,
            id: table.id,
            guid: table.guid,
            isActive: table.isActive,
            angle: table.rotation,
            name: table.name
          };
        }

        this.send('renderGroup', group, increment);
      },
      clearCanvas: function clearCanvas(clearLabels) {
        var objects = this.get('canvas').getObjects();
        var length = objects.length;

        for (var i = length - 1; i >= 0; i -= 1) {
          if (objects[i].meta.type === 'table' || clearLabels) {
            this.get('canvas').remove(objects[i]);
          }
        }

        this.set('nextTableNumber', 1);
        this.get('canvas').renderAll();
      },
      redrawObject: function redrawObject(selection) {
        // I don't think any of this is really necessary
        // maybe it is
        if (selection.meta.type === _constants.SHAPE_LABEL) {
          var label = selection.getObjects().filter(function (x) {
            return x.isType('text');
          }).pop();
          var background = selection.getObjects().filter(function (x) {
            return !x.isType('text');
          }).pop();
          var replacementLabel = new fabric.Text(label.get('text'), _elementStyles.labelDefaults);
          var replacementBackground = new fabric.Rect({
            width: background.width,
            height: background.height,
            fill: _elementStyles.htBlue,
            originX: 'center',
            originY: 'center',
            rx: 2.5,
            ry: 2.5,
            lockScalingX: true,
            lockScalingY: true,
            hasControls: false,
            hasBorders: false
          });
          var posX = selection.get('left');
          var posY = selection.get('top');
          selection.removeWithUpdate(label);
          selection.removeWithUpdate(background);
          selection.addWithUpdate(replacementBackground);
          selection.addWithUpdate(replacementLabel);
          selection.set('angle', background.get('angle'));
          selection.set('left', posX);
          selection.set('top', posY);
        } else {
          var text = selection.getObjects().filter(function (x) {
            return x.isType('text');
          }).pop();
          var shape = selection.getObjects().filter(function (x) {
            return !x.isType('text');
          }).pop();
          var replacementText = new fabric.Text(text.get('text'), _elementStyles.textDefaults);
          var replacementShape = shape.isType(_constants.TABLE_SHAPE_RECT) ? new fabric.Rect(_elementStyles.rectangleDefaults) : new fabric.Circle(_elementStyles.circleDefaults);

          if (shape.isType('circle')) {
            replacementShape.set('radius', shape.get('radius'));
          } else if (shape.isType(_constants.TABLE_SHAPE_RECT)) {
            replacementShape.set('height', shape.get('height'));
            replacementShape.set('width', shape.get('width'));
          }

          var _posX = selection.get('left');

          var _posY = selection.get('top');

          replacementShape.set('stroke', _elementStyles.htBlue);
          replacementShape.set('strokeWidth', 4);
          replacementText.set('fill', '#00ADEF');
          selection.removeWithUpdate(text);
          selection.removeWithUpdate(shape);
          selection.addWithUpdate(replacementShape);
          selection.addWithUpdate(replacementText);
          selection.set('angle', shape.get('angle'));
          selection.set('left', _posX);
          selection.set('top', _posY);
        }

        this.get('canvas').renderAll();
      },
      objectSelected: function objectSelected() {
        this.set('currentSelection', this.get('canvas').getActiveGroup() || this.get('canvas').getActiveObject());
      },
      renderGroup: function renderGroup(group, increment) {
        updatedBoundaries(group, this.get('canvas'));
        this.get('canvas').add(group);

        if (increment) {
          this.incrementProperty('nextTableNumber');
        }
      },
      renameItem: function renameItem(newText) {
        var text = this.get('currentSelection').getObjects().filter(function (x) {
          return x.isType('text');
        }).pop();
        text.set('text', newText);
        var update = {
          name: text.get('text')
        }; // Have to do a little extra to handle redrawing the text
        // background when the selection item is a label

        if (this.get('currentSelection').meta.type === _constants.SHAPE_LABEL) {
          var background = this.get('currentSelection').getObjects().filter(function (x) {
            return x.isType(_constants.TABLE_SHAPE_RECT);
          }).pop();
          background.set('height', text.get('height') + 10);
          background.set('width', text.get('width') + 30);
          update.height = background.get('height');
          update.width = background.get('width');
        }

        this.sendAction('updateTableProps', this.get('currentSelection').meta.guid, update);
        this.send('redrawObject', this.get('currentSelection'));
      },
      download: function download() {
        if (this.get('currentSelection')) {
          this.get('canvas').discardActiveObject();
        }

        var img = this.get('canvas').toDataURL();
        var el = document.createElement('img');
        el.className = 'table-print';
        el.src = img;
        var css = document.createElement('style');
        css.innerHTML = "@media print {\n\t\t\t\tbody > #wrap-content > .site-content {\n\t\t\t\t\tdisplay: none !important;\n\t\t\t\t}\n\t\t\t\tbody > #shift4-app-shell {\n\t\t\t\t\tdisplay: none !important;\n\t\t\t\t}\n\t\t\t\t.table-print {\n\t\t\t\t\tborder: 1px solid rgba(0, 0, 0, 0.15);\n\t\t\t\t\theight: 100%;\n\t\t\t\t\twidth:100%;\n\t\t\t\t\tdisplay: block !important;\n\t\t\t\t}\n\t\t\t}";
        document.body.appendChild(css);
        document.body.appendChild(el);
        Ember.run.next(function () {
          window.print();
          document.body.removeChild(css);
          document.body.removeChild(el);
        });
      }
    }
  });

  _exports.default = _default;
});