define("lh4/adapters/internet-payments/customer/details", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    pathForType: function pathForType() {
      return 'internet-payments/customers';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), "?locationId=").concat(this.location.model.id);
    }
  });

  _exports.default = _default;
});