define("lh4/controllers/authenticated", ["exports", "lh4/config/environment", "lh4/enums/microfrontend-event-keys"], function (_exports, _environment, _microfrontendEventKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    title: Ember.inject.service(),
    permissions: Ember.inject.service(),
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    media: Ember.inject.service(),
    metrics: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    walkthrough: Ember.inject.service(),
    location: Ember.inject.service(),
    session: Ember.inject.service(),
    zendesk: Ember.inject.service(),
    isMicrofrontend: Ember.computed(function getter() {
      return Boolean(this.appShell);
    }),
    spinner: Ember.inject.service(),
    disabledBackElements: null,
    showLaunchControl: false,
    hasLoyaltyProgram: Ember.computed('model.loyaltyPrograms.[]', function getter() {
      return this.model.loyaltyPrograms.length > 0;
    }),
    copyrightYear: _environment.default.copyrightYear,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.closeModal = this.closeModal.bind(this);

      if (this.isMicrofrontend) {
        this.appShell.subscribe(_microfrontendEventKeys.default.SIGNOUT, function () {
          _this.metrics.invoke('logout', 'datadogRum', {});

          _this.session.invalidate();
        });
        this.appShell.subscribe(_microfrontendEventKeys.default.OPEN_HELP_MODAL, function () {
          _this.send('help');
        });
      }
    },
    destroy: function destroy() {
      if (this.isMicrofrontend) {
        this.appShell.unsubscribe(_microfrontendEventKeys.default.SIGNOUT);
        this.appShell.unsubscribe(_microfrontendEventKeys.default.OPEN_HELP_MODAL);
      }
    },
    animateIconContainer: function animateIconContainer(event) {
      var iconContainer = event === null || event === void 0 ? void 0 : event.target.closest('.icon-container');

      if (iconContainer) {
        iconContainer.classList.add('clicked');
        iconContainer.addEventListener('animationend', function () {
          iconContainer.classList.remove('clicked');
        });
      }
    },
    closeModal: function closeModal() {
      this.toggleProperty('showLaunchControl');
    },
    actions: {
      onUserIcon: function onUserIcon(event) {
        this.animateIconContainer(event);
      },
      toggleNav: function toggleNav(event) {
        this.animateIconContainer(event);
        this.toggleProperty('sidebar.isOpen');

        if (this.media.isDesktop) {
          this.metrics.trackEvent('MixpanelCustom', {
            event: 'Hamburger Menu Clicked',
            'Device Type': 'Desktop'
          });
        }
      },
      toggleChat: function toggleChat(event) {
        this.animateIconContainer(event);
        this.zendesk.toggleWidget();
      },
      help: function help(event) {
        this.animateIconContainer(event);

        if (this.walkthrough.key) {
          this.set("walkthrough.data.".concat(this.walkthrough.key), false);
        }
      },
      logout: function logout() {
        this.metrics.invoke('logout', 'datadogRum', {});
        this.session.invalidate();
      },
      selectLocation: function selectLocation() {
        this.send('refresh');
      }
    }
  });

  _exports.default = _default;
});