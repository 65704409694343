define("lh4/routes/authenticated/epro/accounting/taxes/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'tax',
    model: function model() {
      return Ember.RSVP.hash({
        tax: this.store.createRecord('echo-pro/tax', {
          locationId: this.location.model.id
        }),
        items: this.modelFor('authenticated.epro.accounting.taxes').items,
        orderTypes: this.modelFor('authenticated.epro.accounting.taxes').orderTypes,
        taxSets: this.modelFor('authenticated.epro.accounting.taxes').taxSets
      });
    }
  });

  _exports.default = _default;
});