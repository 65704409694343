define("lh4/routes/authenticated/epro/manage-employees/employees/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    queryParams: {
      clone: {
        refreshModel: true
      }
    },
    modelName: 'employee',
    childChangesets: {
      employeeDates: 'dateChangesets',
      employeePhones: 'phoneChangesets',
      payRates: 'payRateChangesets'
    },
    model: function model(_ref) {
      var clone = _ref.clone;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        employee: this.createNewEmployee(clone),
        permissions: this.store.query('echo-pro/permission', {
          location: location
        }),
        jobs: this.store.query('echo-pro/job', {
          location: location
        }),
        printers: this.store.query('echo-pro/printer', {
          location: location
        })
      });
    },
    createNewEmployee: function createNewEmployee(cloneId) {
      if (!cloneId) {
        return this.store.createRecord('echo-pro/employee', {
          locationId: this.location.model.id,
          isActive: true
        });
      } // TODO: use duplicateModel of model-cloneable mixin


      var originalEmployee = this.store.peekRecord('echo-pro/employee', cloneId);
      var copyKeysFromOriginal = ['locationId', 'defaultPrinter', 'permissionIds', 'isActive', 'isLaborTrackingEnabled', 'isRecordingBreaks', 'isPaidHourly'];
      var valuesFromOriginalObject = copyKeysFromOriginal.reduce(function (acc, key) {
        var obj = acc;
        obj[key] = originalEmployee.get(key);
        return obj;
      }, {});
      valuesFromOriginalObject.jobs = originalEmployee.jobs;
      valuesFromOriginalObject.payRates = originalEmployee.payRates;
      return this.store.createRecord('echo-pro/employee', valuesFromOriginalObject);
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // This is to create missing payrates that exist in the jobs array.
      var missingRecords = model.employee.jobs.filter(function (job) {
        return !model.employee.payRates.find(function (payRate) {
          return payRate.jobGuid === job.guid;
        });
      });

      if (missingRecords.length) {
        var createdRecords = missingRecords.map(function (job) {
          return _this.store.createRecord('echo-pro/pay-rate', {
            locationId: job.locationId,
            employeeGuid: model.employee.id,
            jobGuid: job.guid,
            payRate: job.payRate
          });
        });
        model.employee.payRates.pushObjects(createdRecords);
      } // This adjusts the payRate value to the jobs value if the payRate value is less than the payRate value


      model.employee.payRates.map(function (payRate) {
        var job = model.employee.jobs.findBy('id', payRate.jobGuid);

        if (job && payRate.payRate < job.payRate) {
          payRate.set('payRate', job.payRate);
        }

        return payRate;
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // do not use sticky query params
        // https://guides.emberjs.com/v3.15.0/routing/query-params/#toc_sticky-query-param-values
        controller.set('clone', undefined);
      }
    }
  });

  _exports.default = _default;
});