define("lh4/components/modal/user-modal", ["exports", "lh4/classes/tree/tree", "lh4/utils/permissions", "lh4/enums/country-code"], function (_exports, _tree, _permissions, _countryCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _WORKFORCE;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Permissions that can only be changed if user has GRANT_MANAGE_USERS permission
  var MANAGE_USER_PERMISSIONS = [_permissions.default.USERS.GRANT_MANAGE_USERS, _permissions.default.USERS.MANAGE_USERS]; // Permissions that SSO users are not permitted to change

  var SSO_RESTRICTED_PERMISSIONS = [].concat(MANAGE_USER_PERMISSIONS, _toConsumableArray(Object.values(_permissions.default.DISPUTES)), _toConsumableArray(Object.values(_permissions.default.TRANSACTIONS))); // Permissions that are displayed differently on specific conditions

  var REGROUPED_PERMISSIONS = {
    WORKFORCE: (_WORKFORCE = {}, _defineProperty(_WORKFORCE, _permissions.default.HBR.EMPLOYEES_AND_JOBS, {
      category: 'Workforce',
      parentCategory: 'General Settings'
    }), _defineProperty(_WORKFORCE, _permissions.default.HBR.TIMECLOCK, {
      category: 'Workforce',
      parentCategory: 'General Settings',
      description: 'Manage timecards'
    }), _defineProperty(_WORKFORCE, _permissions.default.HBR.EMPLOYEE_SCHEDULING, {
      category: 'Workforce',
      parentCategory: 'General Settings',
      description: 'Manage scheduler'
    }), _WORKFORCE)
  };

  var _default = Ember.Component.extend({
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    session: Ember.inject.service(),
    permissionService: Ember.inject.service('permissions'),
    flashMessages: Ember.inject.service(),
    features: Ember.inject.service(),
    tabs: ['User Information', 'Locations', 'User Permissions'],
    permissionSearchText: '',
    matchLocation: function matchLocation(id) {
      return function (x) {
        return x.l === id || x.locationId === id;
      };
    },
    matchPermission: function matchPermission(id) {
      return function (x) {
        return x.p === id || x.userPermissionId === id;
      };
    },
    canChangePermission: function canChangePermission(intId) {
      var allPermissions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.permissionService.get('tokenData.data').permissions;

      if (this.session.isSSO && SSO_RESTRICTED_PERMISSIONS.includes(intId)) {
        return false;
      }

      if (MANAGE_USER_PERMISSIONS.includes(intId) && !allPermissions.some(this.matchPermission(_permissions.default.USERS.GRANT_MANAGE_USERS))) {
        return false;
      }

      return allPermissions.some(this.matchPermission(intId));
    },
    hiddenPermissions: Ember.computed(function getter() {
      var perms = new Set([_permissions.default.GENERAL.LIGHTHOUSE_ACCESS]); // Add Finaro if there are any non US locations in the manageable ones

      if (!this.manageableLocations.some(function (_ref) {
        var countryCode = _ref.countryCode;
        return countryCode.toUpperCase() !== _countryCode.default.US;
      })) {
        perms.add(_permissions.default.FINARO.FINARO_INSIGHT_ACCESS);
      }

      return _toConsumableArray(perms);
    }),
    editablePermissions: Ember.computed('regroupedPermissions', 'hiddenPermissions', function getter() {
      var _this = this;

      return this.regroupedPermissions.filter(function (perm) {
        return !_this.hiddenPermissions.includes(parseInt(perm.id, 10));
      });
    }),
    manageableLocations: Ember.computed('locations', function getter() {
      var _this2 = this;

      return this.locations.filter(function (location) {
        return _this2.permissionService.hasPermission(_permissions.default.USERS.MANAGE_USERS, location.id);
      });
    }),
    manageablePermissionSets: Ember.computed('user', 'user.permissionSets', 'hiddenPermissions', function getter() {
      var _this3 = this;

      return (this.user.get('permissionSets') || []).reduce(function (acc, _ref2) {
        var locationId = _ref2.locationId,
            permissions = _ref2.permissions;
        var manageablePermissions = permissions.filter(function (permId) {
          return _this3.canChangePermission(permId) && !_this3.hiddenPermissions.includes(permId);
        });

        if (manageablePermissions.length) {
          acc.push({
            locationId: locationId,
            permissions: manageablePermissions
          });
        }

        return acc;
      }, []);
    }),
    selectedLocations: Ember.computed('manageableLocations', 'manageablePermissionSets', function getter() {
      var _this4 = this;

      return this.manageableLocations.filter(function (_ref3) {
        var id = _ref3.id;
        return _this4.manageablePermissionSets.some(function (_ref4) {
          var locationId = _ref4.locationId;
          return locationId.toString() === id;
        });
      });
    }),
    regroupedPermissions: Ember.computed('permissions', function getter() {
      var _this5 = this;

      var WORKFORCE = REGROUPED_PERMISSIONS.WORKFORCE; // Permissions regrouped and renamed for workforce users

      if (WORKFORCE && this.can.can('manage workforce for echo-pro/workforce', this.location.model)) {
        Object.entries(WORKFORCE).forEach(function (_ref5) {
          var _ref6 = _slicedToArray(_ref5, 2),
              permissionId = _ref6[0],
              newValues = _ref6[1];

          var permission = _this5.permissions.find(function (_ref7) {
            var id = _ref7.id;
            return +id === +permissionId;
          });

          if (permission) {
            Object.entries(newValues).forEach(function (_ref8) {
              var _ref9 = _slicedToArray(_ref8, 2),
                  key = _ref9[0],
                  value = _ref9[1];

              permission[key] = value;
            });
          }
        });
      }

      return this.permissions;
    }),
    selectedPermissions: Ember.computed('editablePermissions', 'selectedLocations.[]', 'manageableLocations', function getter() {
      var _this6 = this;

      var allPermissions = this.permissionService.get('tokenData.data').permissions;
      return this.user.get('isNew') ? this.editablePermissions.filterBy('isOnByDefault').filter(function (perm) {
        return _this6.manageableLocations.some(function (location) {
          return allPermissions.filter(_this6.matchLocation(location.id)).map(function (x) {
            return _this6.canChangePermission(perm.id, x);
          });
        });
      }) : this.editablePermissions.filter(function (_ref10) {
        var id = _ref10.id;
        var intId = parseInt(id, 10);
        return _this6.user.get('permissionSets').find(function (_ref11) {
          var locationId = _ref11.locationId,
              permissions = _ref11.permissions;
          return permissions.includes(intId) && _this6.selectedLocations.some(function (location) {
            return location.id === locationId.toString();
          });
        });
      });
    }),
    permissionsList: Ember.computed('editablePermissions', 'selectedPermissions', 'selectedLocations.[]', function getter() {
      var _this7 = this;

      // transform BE response to adjacency list
      var root = {
        id: 'root',
        name: 'All permissions',
        parentId: null
      };
      var parentCategories = new Map();
      var categories = new Map();
      var list = [root];
      var selectedPermissionsIds = this.selectedPermissions.map(function (_ref12) {
        var id = _ref12.id;
        return id;
      });
      var allPermissions = this.permissionService.get('tokenData.data').permissions;
      var selectedLocationsInt = this.selectedLocations.map(function (location) {
        return parseInt(location.id, 10);
      });
      this.editablePermissions.toArray().forEach(function (_ref13) {
        var id = _ref13.id,
            description = _ref13.description,
            category = _ref13.category,
            parentCategory = _ref13.parentCategory;
        var intId = parseInt(id, 10);
        var parentCat = {
          id: "p_".concat(parentCategory),
          name: parentCategory,
          parentId: root.id
        };
        var cat = {
          id: "c_".concat(category),
          name: category,
          parentId: parentCat.id
        };
        parentCategories.set(parentCat.id, parentCat);
        categories.set(cat.id, cat);
        list.push({
          id: id,
          name: description,
          parentId: cat.id,
          isSelected: _this7.selectedLocations.length ? selectedPermissionsIds.includes(id) : false,
          isDisabled: selectedLocationsInt.length ? selectedLocationsInt.some(function (locationId) {
            var locationPermissions = allPermissions.filter(_this7.matchLocation(locationId));
            return !_this7.canChangePermission(intId, locationPermissions);
          }) : true
        });
      });
      list.push.apply(list, _toConsumableArray(parentCategories.values()).concat(_toConsumableArray(categories.values())));
      return list;
    }),
    permissionsTree: Ember.computed('permissionsList', function getter() {
      return new _tree.default(this.permissionsList, {
        isVisible: true,
        isSelected: false,
        isExpanded: false
      });
    }),
    isUserSelf: Ember.computed('user', function getter() {
      return this.permissionService.get('tokenData.data').sub === parseInt(this.user.get('id'), 10);
    }),
    actions: {
      modalDeny: function modalDeny() {
        this.sendAction('modalDeny');
      },
      saveUser: function saveUser() {
        var _this8 = this;

        if (this.selectedLocations.length === 0) {
          return this.flashMessages.danger('Please select a location', {
            class: 'ui error message'
          });
        }

        var permissions = this.permissionsTree.selected.filter(function (_ref14) {
          var isLeaf = _ref14.isLeaf;
          return isLeaf;
        }).map(function (_ref15) {
          var id = _ref15.id;
          return parseInt(id, 10);
        });

        if (!permissions.includes(_permissions.default.GENERAL.LIGHTHOUSE_ACCESS)) {
          permissions.push(_permissions.default.GENERAL.LIGHTHOUSE_ACCESS);
        }

        var allPermissions = this.permissionService.get('tokenData.data').permissions; // Start building permission sets payload to be sent to backend based on selections

        var permissionSets = this.selectedLocations.map(function (_ref16) {
          var id = _ref16.id;
          var locationId = parseInt(id, 10);
          var locationPermissions = allPermissions.filter(_this8.matchLocation(locationId));
          return {
            locationId: locationId,
            permissions: permissions.filter(function (permId) {
              return _this8.canChangePermission(permId, locationPermissions);
            })
          };
        }); // Find all permissions that current user is not allowed to manage and preserve those permissions

        var preservedPermissionSets = (this.user.get('permissionSets') || []).map(function (_ref17) {
          var locationId = _ref17.locationId,
              permIds = _ref17.permissions;
          var locationPermissions = allPermissions.filter(_this8.matchLocation(locationId));
          return {
            locationId: locationId,
            permissions: permIds.filter(function (permId) {
              return !_this8.canChangePermission(permId, locationPermissions);
            })
          };
        }).filter(function (_ref18) {
          var permIds = _ref18.permissions;
          return permIds.length;
        }); // Merge permission sets to be preserved into permission sets payload

        preservedPermissionSets.forEach(function (preservedPermSet) {
          var locationId = preservedPermSet.locationId,
              permIds = preservedPermSet.permissions;

          if (!permIds.includes(_permissions.default.GENERAL.LIGHTHOUSE_ACCESS)) {
            permIds.push(_permissions.default.GENERAL.LIGHTHOUSE_ACCESS);
          }

          var permSet = permissionSets.findBy('locationId', locationId);

          if (permSet) {
            permSet.permissions = Array.from(new Set(permSet.permissions.concat(permIds)));
            return;
          }

          permissionSets.push(preservedPermSet);
        });
        var locationIds = this.selectedLocations.map(function (_ref19) {
          var id = _ref19.id;
          return parseInt(id, 10);
        });
        var permissionIds = permissions.filter(function (id) {
          return _this8.canChangePermission(id);
        });
        var normalizedPermissionSets = {
          locationIds: locationIds,
          permissionIds: permissionIds
        };
        this.sendAction('saveUser', permissionSets, normalizedPermissionSets);
        return false;
      }
    }
  });

  _exports.default = _default;
});