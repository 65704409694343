define("lh4/components/ui/tree/checkbox-tree", ["exports", "lh4/classes/tree/tree"], function (_exports, _tree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkbox-tree'],
    isSearchable: true,
    searchText: '',
    includeRootNode: true,
    media: Ember.inject.service(),
    root: Ember.computed('tree', function getter() {
      return this.tree.root;
    }),
    nodes: Ember.computed('includeRootNode', 'tree', function getter() {
      // root node displayed, return only children
      if (this.includeRootNode) {
        return this.root.children;
      } // probably multiple roots or we're not displaying the whole tree


      return this.tree.nodes;
    }),
    levelShift: Ember.computed('nodes', function getter() {
      // in case we display tree not from root, all nodes should be shifted to the left
      return this.nodes[0].level;
    }),
    didInsertElement: function didInsertElement() {
      if (this.isSearchable) {
        // focus search on initial render and, ie., after tab switch
        var $input = this.element.querySelector('.general-search input'); // Focus on the input element if it exists and is not mobile

        if (!this.media.isMobile && $input) {
          $input.focus();
        }
      }
    },
    didRender: function didRender() {
      if (this.onTreeChange) {
        this.onTreeChange();
      }
    },
    actions: {
      selectNode: function selectNode() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        // action can be called directly for root node
        // or bubbled recursively from checkbox-node actions, thus params may differ
        // seems like all options to prevent event bubbling is not working with recursive components
        var _ref = [].concat(args),
            node = _ref[0],
            value = _ref[1];

        var nextArgs = typeof value === 'boolean' ? {
          node: node,
          value: value
        } : [].concat(args).pop();
        this.tree.selectNode(nextArgs.node, nextArgs.value, this.onCheck);
      },
      toggleIsExpanded: function toggleIsExpanded() {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        // action can be called directly for root node
        // or bubbled recursively from checkbox-node actions, thus params may differ
        // seems like all options to prevent event bubbling is not working with recursive components
        _tree.default.toggleIsExpanded([].concat(args).pop());
      },
      expandAll: function expandAll() {
        _tree.default.expandAll(this.root);
      },
      collapseAll: function collapseAll() {
        _tree.default.collapseAll(this.root);
      },
      onSearch: function onSearch(searchText) {
        this.tree.filter(searchText.toLowerCase());
      }
    }
  });

  _exports.default = _default;
});