define("lh4/services/customers-import", ["exports", "ember-inflector", "lh4/utils/text-parser", "lh4/models/echo-pro/customer", "lh4/models/processing/customer", "lh4/utils/states"], function (_exports, _emberInflector, _textParser, _customer, _customer2, _states) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SUPPORTED_FILE_TYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv', 'text/tab-separated-values'];
  var REQUIRED_COLUMN_VALIDATIONS = [{
    field: 'firstName',
    message: 'First Name is mandatory'
  }, {
    field: 'lastName',
    message: 'Last Name is mandatory'
  }];
  var DISPLAY_EXCEPTIONS = {
    postalCode: 'zip'
  };

  var HUMANIZE_VALIDATION_EXCEPTIONS = _objectSpread({
    number: 'phone number',
    phone: 'phone number'
  }, DISPLAY_EXCEPTIONS);
  /**
   * Insert spaces between lower case letter and upper case letter.
   *
   * @param {String} value - actual string to insert spaces.
   * @param {Boolean} shouldCapitalize - capitalize first word letter.
   * @returns {String} - result string.
   */


  function insertSpaces(string) {
    var shouldCapitalize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var value = string;

    if (shouldCapitalize) {
      value = Ember.String.capitalize(value);
    }

    value = value.replace(/([a-z])([A-Z])/, '$1 $2');

    if (!shouldCapitalize) {
      value = value.toLowerCase();
    }

    return value;
  }
  /**
   * Build attribute display name.
   *
   * @param {String} attribute - attribute name.
   * @param {String} prefix - attribute prefix, example: 'Primary', 'Secondary'.
   * @param {String} postfix - attribute postfix , example: 'Zip', 'City'.
   * @returns {String} - result attribute display name.
   */


  function buildAttributeDisplayName(attributeName, prefix, postfixString) {
    var attribute = (0, _emberInflector.singularize)(attributeName);
    attribute = insertSpaces(attribute, true);
    var result = attribute;

    if (prefix) {
      result = "".concat(prefix, " ").concat(result);
    }

    if (postfixString) {
      var postfix = DISPLAY_EXCEPTIONS[postfixString] || postfixString;
      postfix = Ember.String.classify(postfix);
      result = "".concat(result, " ").concat(postfix);
    }

    return result;
  }
  /**
   * Build customer attribute object.
   *
   * @param {String} name - attribute name.
   * @param {Boolean} isUnique - attribute is unique.
   * @returns {Object} - result customer attribute.
   */


  function buildAttribute(name, isUnique) {
    return {
      displayName: buildAttributeDisplayName(name),
      field: name,
      isUnique: isUnique,
      setter: function setter(customer, value) {
        customer.set(name, value);
        return {
          source: customer,
          path: name
        };
      }
    };
  }
  /**
   * Transform importing value.
   * Right now supports only 'state', it will be transformed to short state name, example: 'Pennsylvania' to 'PA'.
   *
   * @param {String} field - field name to transform value for.
   * @param {String} value - actual value to transform.
   * @returns {String} - transformed value.
   */


  function transformValue(field, value) {
    if (field === 'state') {
      return (0, _states.shortenStateName)(value);
    }

    return value;
  }
  /**
   * Build attributes for hasMany relation.
   *
   * @param {String} name - relation name.
   * @param {Array} types - relation types, example: 'Primary', 'Home'.
   * @param {Array} fields - relation fields, example: 'Zip', 'City'.
   * @param {String} modelName - Ember.Model name.
   * @param {Array} uniqueTypeNames - unique relation type names.
   * @returns {Array} - customer hasMany attributes.
   */


  function buildHasManyAttributes(name, types, fields, modelName) {
    var _ref;

    var usePostfix = fields.length > 1;
    var groupedByTypes = types.map(function (type) {
      return fields.map(function (field) {
        return {
          displayName: buildAttributeDisplayName(name, type.name, usePostfix && field),
          type: type,
          field: field,
          setter: function setter(customer, value) {
            var transformedValue = transformValue(field, value);
            var existing = customer.get(name).find(function (i) {
              return i.get('type') === type.name && Ember.isEmpty(i.get(field));
            });

            if (existing) {
              /* eslint-disable no-param-reassign */
              if (field === 'region/county') {
                field = 'state';
              }

              if (field === 'postCode') {
                field = 'postalCode';
              }

              existing.set(field, transformedValue);
            } else {
              existing = customer.addItem(name, modelName, _defineProperty({
                type: type.name
              }, field, transformedValue));
              customer[name].pushObject(existing);
            }

            return {
              source: existing,
              path: field,
              transformed: transformedValue !== value
            };
          }
        };
      });
    });
    return (_ref = []).concat.apply(_ref, _toConsumableArray(groupedByTypes));
  }
  /**
   * Build header object.
   *
   * @param {String} displayName - header display name.
   * @returns {Object} - result header object.
   */


  function buildHeader(displayName) {
    return {
      displayName: displayName,
      isHeader: true
    };
  }
  /**
   * Humanize attribute name.
   *
   * @param {String} attribute - attribute name.
   * @returns {String} - result humanized attribute name.
   */


  function humanize(attributeName) {
    var attribute = (0, _emberInflector.singularize)(attributeName);
    return HUMANIZE_VALIDATION_EXCEPTIONS[attribute] || insertSpaces(attribute);
  }
  /**
   * Check if file has extension.
   *
   * @param {Array} extensions - extensions to check.
   * @param {Object} file - file object.
   * @returns {Boolean} - check result.
   */


  function hasExtension(extensions, file) {
    return extensions.some(function (ext) {
      return file.name.endsWith(ext);
    });
  }
  /**
   * Wrap with checking for empty values.
   *
   * @param {Function} action - callback to execute.
   * @returns {Function} - result function.
   */


  function skipEmptyValues(action) {
    return function (customer, value) {
      return Ember.isBlank(value) ? null : action(customer, value);
    };
  }
  /**
   * Read file.
   *
   * @param {Function} resolve - resolve callback.
   * @param {Function} reject - reject callback.
   * @param {Function} readAction - read file callback.
   */


  function readFile(resolve, reject, readAction) {
    var fileReader = new FileReader();

    fileReader.onloadend = function () {
      try {
        var bytes = new Uint8Array(fileReader.result);
        var binary = bytes.reduce(function (acc, byte) {
          return acc + String.fromCharCode(byte);
        }, '');
        resolve(binary);
      } catch (e) {
        reject(e.message || e);
      }
    };

    fileReader.onerror = function () {
      return reject('An unexpected error has occurred, please try again');
    };

    readAction(fileReader);
  }
  /**
   * Build wrapper for customer.
   *
   * @param {Ember.Model} customer - customer to wrap.
   * @returns {Object} - wrapped customer with some fields useful for values and validation display.
   */


  function buildWrapper(customer) {
    return Ember.Object.extend({
      customer: customer,
      attributes: [],
      validations: Ember.computed.alias('customer.validations')
    }).create();
  }

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    location: Ember.inject.service(),
    region: Ember.inject.service('locale/region'),
    router: Ember.inject.service(),
    supportedFiles: SUPPORTED_FILE_TYPES.join(),
    product: Ember.computed('location.model.product', function getter() {
      var product = this.get('location.model.product');

      if (product === 'terminal' || product === 'salon' || product === 'mozart') {
        product = 'processing';
      }

      return product;
    }),
    currentTypes: Ember.computed('product', function getter() {
      var product = this.get('product');
      var types = {};

      switch (product) {
        case 'echo-pro':
          types = {
            address: _customer.addressTypes,
            email: _customer.emailTypes,
            phone: _customer.phoneTypes
          };
          break;

        case 'processing':
          types = {
            address: _customer2.processingEmailTypes,
            email: _customer2.processingPhoneTypes,
            phone: _customer2.processingAddressTypes
          };
          break;

        default:
          break;
      }

      return types;
    }),
    customerAttributes: Ember.computed('currentTypes', function getter() {
      return [buildAttribute('firstName', true), buildAttribute('lastName', true), buildHeader('Phone')].concat(_toConsumableArray(buildHasManyAttributes('phones', this.get('currentTypes.phone'), ['number'], "".concat(this.get('product'), "/customer/phone"))), [buildHeader('Email')], _toConsumableArray(buildHasManyAttributes('emails', this.get('currentTypes.email'), ['email'], "".concat(this.get('product'), "/customer/email"))), [buildHeader('Address')], _toConsumableArray(buildHasManyAttributes('addresses', this.get('currentTypes.address'), this.region.location.isUS ? ['streetAddress1', 'streetAddress2', 'city', 'state', 'postalCode'] : ['streetAddress1', 'streetAddress2', 'city', 'region/county', 'postCode'], "".concat(this.get('product'), "/customer/address"))));
    }),
    parse: function parse(file) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!file) {
          return reject('The file is not selected');
        }

        if (hasExtension(['.csv', '.tsv'], file)) {
          readFile(function (result) {
            return resolve((0, _textParser.textToValuesMatrix)(result));
          }, reject, function (fileReader) {
            return fileReader.readAsArrayBuffer(file);
          });
        } else if (hasExtension(['.xls', '.xlsx'], file)) {
          readFile(function (result) {
            var workbook = XLSX.read(result, {
              type: 'binary'
            });
            var rows = (0, _textParser.spreadsheetToValuesMatrix)(workbook);
            resolve(rows);
          }, reject, function (fileReader) {
            return fileReader.readAsArrayBuffer(file);
          });
        }

        return false;
      }).then(function (matrix) {
        return _this.toColumns(_this.toTable(matrix));
      });
    },
    toTable: function toTable(matrix) {
      return {
        header: matrix[0],
        rows: matrix.slice(1)
      };
    },
    toColumns: function toColumns(table) {
      var attributes = this.get('customerAttributes').filter(function (attribute) {
        return !attribute.isHeader;
      });
      return table.header.map(function (name, index) {
        var existing = attributes.find(function (attribute) {
          return attribute.displayName === name;
        });

        if (existing && existing.isUnique) {
          attributes.removeObject(existing);
        }

        return Ember.Object.create({
          attribute: existing,
          status: existing ? 'undefined' : 'unknown',
          values: table.rows.map(function (row) {
            return row[index];
          })
        });
      });
    },
    toCustomers: function toCustomers(columns, location) {
      var _this2 = this;

      var count = Math.max.apply(Math, _toConsumableArray(columns.map(function (column) {
        return column.get('values').length;
      })));
      var wrappers = Array.from({
        length: count
      }, function () {
        return _this2.store.createRecord("".concat(_this2.get('product'), "/customer"), {
          locationId: location.id
        });
      }).map(function (customer) {
        return buildWrapper(customer);
      });
      columns.forEach(function (column) {
        var setter = skipEmptyValues(column.attribute.setter);
        wrappers.forEach(function (wrapper, index) {
          var customer = wrapper.customer;
          var value = column.get('values')[index];
          var attribute = setter(customer, value);
          wrapper.attributes.pushObject(attribute);
        });
      });
      return wrappers;
    },
    validateColumns: function validateColumns(columns) {
      var fields = columns.filterBy('status', 'defined').mapBy('attribute.field');
      return REQUIRED_COLUMN_VALIDATIONS.filter(function (check) {
        return fields.every(function (field) {
          return check.field !== field;
        });
      }).mapBy('message');
    },
    validateCustomers: function validateCustomers(customers) {
      var invalid = customers.filter(function (row) {
        return row.get('customer.validations.isValid') === false;
      });
      var summary = [];
      invalid.forEach(function (customer) {
        var attributes = customer.get('customer.validations.errors').map(function (e) {
          return humanize(e.attribute);
        }).uniq();
        attributes.forEach(function (attribute) {
          var existing = summary.findBy('name', attribute);

          if (existing) {
            existing.count += 1;
          } else {
            summary.pushObject({
              name: attribute,
              count: 1
            });
          }
        });
      });
      return {
        total: invalid.length,
        hasTransforms: customers.some(function (c) {
          return c.get('attributes').some(function (a) {
            return a && a.transformed;
          });
        }),
        summary: summary
      };
    }
  });

  _exports.default = _default;
});