define("lh4/services/corporation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    location: Ember.inject.service(),
    isLoading: false,
    locationMenuSettings: null,
    isMenuManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      // indicates that current location's menu entities are managed by corporate
      var locationMenuSettings = this.locationMenuSettings;

      if (locationMenuSettings && locationMenuSettings.get('locationId') === this.location.model.id) {
        return locationMenuSettings.get('menuManaged');
      }

      this.loadLocationMenuSettings(this.location.model.id);
      return false;
    }),
    isManagedByCorporate: function isManagedByCorporate(settingName) {
      // indicates that current location's menu entities are managed by corporate
      // and that current location is not allowed to edit active flags
      var locationMenuSettings = this.locationMenuSettings;

      if (locationMenuSettings && locationMenuSettings.get('locationId') === this.location.model.id) {
        return locationMenuSettings.get('menuManaged') && !locationMenuSettings.get(settingName);
      }

      this.loadLocationMenuSettings(this.location.model.id);
      return false;
    },
    isDepartmentsActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowDepartmentsActiveToggleOverrides');
    }),
    isCoursesActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowCoursesActiveToggleOverrides');
    }),
    isDiscountsActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowDiscountsActiveToggleOverrides');
    }),
    isGratuitiesActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowGratuitiesActiveToggleOverrides');
    }),
    isItemsActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowItemsActiveToggleOverrides');
    }),
    isModifierSetsActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowModifierSetsActiveToggleOverrides');
    }),
    isModifiersActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowModifiersActiveToggleOverrides');
    }),
    isOrderTypesActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowOrderTypesActiveToggleOverrides');
    }),
    isPriceSchedulesActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowPriceSchedulesActiveToggleOverrides');
    }),
    isDynamicPricingActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowDynamicPricingActiveToggleOverrides');
    }),
    isSurchargesActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowSurchargesActiveToggleOverrides');
    }),
    isTaxSetsActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowTaxSetsActiveToggleOverrides');
    }),
    isTaxesActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowTaxesActiveToggleOverrides');
    }),
    isTendersActiveFlagManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowTendersActiveToggleOverrides');
    }),
    isPriceManagedByCorporate: Ember.computed('location.model.id', 'locationMenuSettings', function getter() {
      return this.isManagedByCorporate('allowPriceOverrides');
    }),
    loadLocationMenuSettings: function loadLocationMenuSettings(locationId) {
      var _this = this;

      if (!locationId || this.get('isLoading')) {
        return;
      }

      this.set('isLoading', true);
      this.store.queryRecord('echo-pro/setting', {
        location: locationId,
        type: 'managed_menus'
      }).then(function (settings) {
        var locationMenuSettings = settings.managedMenus;
        locationMenuSettings.set('locationId', locationId);

        _this.setProperties({
          locationMenuSettings: locationMenuSettings,
          isLoading: false
        });
      });
    }
  });

  _exports.default = _default;
});