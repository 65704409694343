define("lh4/components/epro/menu/availability-price/dynamic-price-schedule", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getDaysOfWeekRangeString(schedule) {
    var weekdayOptions = [{
      key: 'isOnMonday',
      label: 'Mon'
    }, {
      key: 'isOnTuesday',
      label: 'Tue'
    }, {
      key: 'isOnWednesday',
      label: 'Wed'
    }, {
      key: 'isOnThursday',
      label: 'Thu'
    }, {
      key: 'isOnFriday',
      label: 'Fri'
    }, {
      key: 'isOnSaturday',
      label: 'Sat'
    }, {
      key: 'isOnSunday',
      label: 'Sun'
    }];
    var isPreviousDayActive = false;
    var ranges = weekdayOptions.reduce(function (acc, currentDay) {
      var isCurrentDayActive = schedule.get(currentDay.key);

      if (!isCurrentDayActive) {
        isPreviousDayActive = false;
        return acc;
      }

      if (isPreviousDayActive) {
        acc[acc.length - 1].end = currentDay.label;
        acc[acc.length - 1].count += 1;
      } else {
        acc.push({
          start: currentDay.label,
          end: currentDay.label,
          count: 1
        });
      }

      isPreviousDayActive = true;
      return acc;
    }, []).map(function (range) {
      if (range.count === 1) {
        return range.start;
      }

      if (range.count === 7) {
        return 'Every Day';
      }

      return "".concat(range.start, " - ").concat(range.end);
    });
    return ranges.join(', ');
  }

  function getTimeString(schedule) {
    if (schedule.beginTimeMilliseconds <= 0 && schedule.endTimeMilliseconds <= 0) {
      return '';
    }

    var start = _moment.default.utc(schedule.beginTimeMilliseconds);

    var end = _moment.default.utc(schedule.endTimeMilliseconds);

    return "".concat(start.format('h:mm A'), " - ").concat(end.format('h:mm A'));
  }

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    classNames: ['dynamic-price-schedule', 'ui', 'field'],
    classNameBindings: ['disabled'],
    showErrors: false,
    type: 'Custom Schedule',
    revenueCenterNames: '',
    defaultPrice: '',
    schedule: null,
    scheduleDescription: Ember.computed('schedule', function getter() {
      return "".concat(getDaysOfWeekRangeString(this.schedule), " ").concat(getTimeString(this.schedule));
    }),
    actions: {
      setPriceType: function setPriceType(type) {
        this.changeset.set('priceType', type);
        this.onPriceChange(this.changeset);
      },
      setPriceValue: function setPriceValue(value) {
        this.changeset.set('priceValue', value);
        this.onPriceChange(this.changeset);
      },
      onClear: function onClear(changeset) {
        changeset.set('priceValue', null);
        changeset.set('priceType', 'Dollars');
      }
    }
  });

  _exports.default = _default;
});