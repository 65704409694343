define("lh4/services/location", ["exports", "lh4/enums/timezone", "lh4/enums/country-code", "moment", "lh4/enums/account-status", "lh4/constants/location", "lh4/enums/products"], function (_exports, _timezone, _countryCode, _moment, _accountStatus, _location, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    /* Selected Location */
    model: null,
    sessionStorage: Ember.inject.service(),
    hasInternetPaymentProduct: false,
    hasMultipleLocations: false,
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    metrics: Ember.inject.service(),
    hardware: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    launchControl: Ember.inject.service('launch-control'),
    locale: Ember.inject.service(),
    can: Ember.inject.service(),
    userPreferenceTimezone: null,
    workflow: Ember.inject.service(),
    loadLocation: function loadLocation(id) {
      var _this = this;

      if (!Number.isInteger(parseInt(id, 10))) {
        throw new Error('locationId required');
      }

      this.set('isLoading', true);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this.store.findRecord('location', id).then(function (record) {
          return _this._afterLocationLoaded(record, resolve);
        }).catch(reject);
      });
    },

    get isUS() {
      return this.model.countryCode === _countryCode.default.US;
    },

    get isCA() {
      return this.model.countryCode === _countryCode.default.CA;
    },

    // Temporary method to hide certain menu items for Canada location
    get isTemporarilyHiddenForCA() {
      return this.isCA;
    },

    get usesShift4PaymentProcessor() {
      return this.model.usesShift4PaymentProcessor;
    },

    _afterLocationLoaded: function _afterLocationLoaded(record, resolve) {
      var _this2 = this;

      var loadDependencies = this.launchDarkly.setFeatureFlags(record).then(function () {
        return Ember.RSVP.all([_this2.launchControl.loadWorkflows(record === null || record === void 0 ? void 0 : record.id), _this2._loadEproSettings(record === null || record === void 0 ? void 0 : record.id)]);
      });
      return loadDependencies.then(function () {
        var _record$name;

        _this2.setProperties({
          model: record,
          isLoading: false,
          hasInternetPaymentProduct: record.product === _products.default.INTERNET_PAYMENTS
        }); // Use ember simple auth to store locationId alongside session data
        // Will be stored in either localStorage or cookies automatically
        // https://github.com/simplabs/ember-simple-auth/issues/805


        _this2.session.set('data.locationId', record === null || record === void 0 ? void 0 : record.id);

        _this2.metrics.invoke('addContext', 'MixpanelCustom', {
          merchantId: record === null || record === void 0 ? void 0 : record.merchantId,
          locationId: record === null || record === void 0 ? void 0 : record.id,
          locationProduct: record === null || record === void 0 ? void 0 : record.product,
          isSSO: _this2.session.isSSO
        });

        _this2.metrics.invoke('addContext', 'datadogRum', {
          locationId: record === null || record === void 0 ? void 0 : record.id,
          locationName: record === null || record === void 0 ? void 0 : (_record$name = record.name) === null || _record$name === void 0 ? void 0 : _record$name.toUpperCase(),
          merchantId: record === null || record === void 0 ? void 0 : record.merchantId,
          shift4Serial: record === null || record === void 0 ? void 0 : record.shift4Serial,
          locationProduct: record === null || record === void 0 ? void 0 : record.product,
          locationCountry: record === null || record === void 0 ? void 0 : record.countryCode
        }); // need to set timezone according to location rules if location was changed
        // setting default timezone after it was set already does no result, so need to reset it before
        // https://momentjs.com/timezone/docs/#/using-timezones/default-timezone/


        _moment.default.tz.setDefault();

        var timezone = _this2.getLocationTimezone();

        _moment.default.tz.setDefault(timezone);

        _this2.hardware.loadItems();

        _this2.locale.setupIntlForLocale(timezone);

        return resolve(record);
      });
    },
    _loadEproSettings: function _loadEproSettings(locationId) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (res, rej) {
        return _this3.store.queryRecord('echo-pro/setting', {
          location: locationId,
          name: 'time_zone',
          type: 'hardware'
        }).then(function (result) {
          var hardware = result === null || result === void 0 ? void 0 : result.hardware;

          if (hardware) {
            _this3.setProperties({
              eproSettings: {
                hardware: hardware.content.toJSON()
              }
            });
          }

          res(result);
        }).catch(rej);
      });
    },
    getLocationTimezone: function getLocationTimezone() {
      var _this$eproSettings, _this$eproSettings$ha, _this$model$timezone;

      var eproTimezone = (_this$eproSettings = this.eproSettings) === null || _this$eproSettings === void 0 ? void 0 : (_this$eproSettings$ha = _this$eproSettings.hardware) === null || _this$eproSettings$ha === void 0 ? void 0 : _this$eproSettings$ha.timeZone;

      if (eproTimezone) {
        return eproTimezone;
      }

      return (_this$model$timezone = this.model.timezone) !== null && _this$model$timezone !== void 0 ? _this$model$timezone : _timezone.default.UTC;
    },

    get isLaunchControlActive() {
      return this.model.accountStatus != null;
    },

    get isLaunchControlAccountDeclined() {
      return this.accountStatus === _accountStatus.ACCOUNT_STATUS_CODES.DECLINED;
    },

    get accountStatus() {
      return parseInt(this.model.accountStatus, 10);
    },

    getLocationAccountStatus: function getLocationAccountStatus() {
      if (_location.PENDING_ACCOUNT_STATUSES.includes(parseInt(this.model.accountStatus, 10))) {
        return _accountStatus.ACCOUNT_STATUS.PENDING;
      }

      if (_location.APPROVED_ACCOUNT_STATUSES.includes(parseInt(this.model.accountStatus, 10))) {
        return _accountStatus.ACCOUNT_STATUS.APPROVED;
      }

      if (_location.BLOCKED_ACCOUNT_STATUSES.includes(parseInt(this.model.accountStatus, 10))) {
        return _accountStatus.ACCOUNT_STATUS.BLOCKED;
      }

      return _accountStatus.ACCOUNT_STATUS.UNKNOWN;
    },

    get treeNodeDefinitions() {
      return this.store.peekAll('location').reduce(function (acc, record) {
        var location = record.serialize();

        if (location.shift4Serial) {
          var serialId = "serial-".concat(location.shift4Serial);
          var serialGroup = acc.find(function (_ref) {
            var _groupId = _ref._groupId;
            return _groupId === serialId;
          });

          if (!serialGroup) {
            acc.push({
              _groupId: serialId,
              id: serialId,
              name: location.legalName,
              product: location.product,
              parentId: null,
              sortInteger: 0
            });
          }

          acc.push(_objectSpread(_objectSpread({}, location), {}, {
            parentId: serialId,
            id: record.id
          }));
        } else {
          var uncategorizedSerialGroup = acc.find(function (_ref2) {
            var _groupId = _ref2._groupId;
            return _groupId === 'uncategorized';
          });

          if (!uncategorizedSerialGroup) {
            acc.push({
              _groupId: 'uncategorized',
              id: 'uncategorized',
              name: 'Uncategorized',
              product: 'uncategorized',
              parentId: null,
              sortInteger: 1
            });
          }

          acc.push(_objectSpread(_objectSpread({}, location), {}, {
            parentId: 'uncategorized',
            id: record.id
          }));
        }

        return acc;
      }, []);
    }

  });

  _exports.default = _default;
});