define("lh4/components/epro/accounting/tax-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    corporation: Ember.inject.service(),
    classNames: ['tax-form'],
    locale: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.taxTypeOptions = [{
        value: true,
        name: this.intl.t('components:epro:accounting:tax-form:tax-type-options:flat', {
          currencySymbol: this.locale.currencySymbol
        })
      }, {
        value: false,
        name: this.intl.t('components:epro:accounting:tax-form:tax-type-options:percentage')
      }];
    },
    hasNoLiquorItem: Ember.computed('changeset.itemIds.[]', 'taxSets', function getter() {
      var _this = this;

      var liquorItem = this.changeset.get('itemIds').find(function (changesetItemId) {
        var changesetItem = _this.items.findBy('id', changesetItemId);

        var taxSet = _this.taxSets.findBy('id', changesetItem.taxSetGuid);

        if (taxSet) {
          return taxSet.isAllLiquor;
        }

        return false;
      });
      return !liquorItem;
    }),
    actions: {
      removeItem: function removeItem(selected, property) {
        this.changeset.set(property, selected);

        if (this.hasNoLiquorItem) {
          this.set('changeset.isNotAppliedIfAllLiquor', false);
          this.set('changeset.isHiddenIfAllLiquor', false);
        }
      }
    }
  });

  _exports.default = _default;
});