define("lh4/routes/authenticated/epro/accounting/taxes/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'tax',
    model: function model(_ref) {
      var taxId = _ref.taxId;
      return Ember.RSVP.hash({
        tax: this.store.findRecord('echo-pro/tax', taxId),
        items: this.modelFor('authenticated.epro.accounting.taxes').items,
        orderTypes: this.modelFor('authenticated.epro.accounting.taxes').orderTypes,
        taxSets: this.modelFor('authenticated.epro.accounting.taxes').taxSets
      });
    }
  });

  _exports.default = _default;
});