define("lh4/templates/authenticated/social-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eNyFgvs6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"social-media\"],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui no-gutter grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segments segments-basic\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment reservation-nav top attached page-nav\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"manage posts for social-medium\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"tab-selection\",\"authenticated.social-media.post-scheduler\"]],{\"statements\":[[0,\"Post Scheduler\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"can\",[\"manage accounts for social-medium\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"tab-selection\",\"authenticated.social-media.accounts\"]],{\"statements\":[[0,\"Social Accounts\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"can\",[\"manage posts for social-medium\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"tab-selection\",\"authenticated.social-media.analytics\"]],{\"statements\":[[0,\"Analytics\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/social-media.hbs"
    }
  });

  _exports.default = _default;
});