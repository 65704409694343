define("lh4/mirage/fixtures/internet-payments/report-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTERNET_PAYMENTS_REPORT_LIST_FIXTURE = {
    'internet-payments': [{
      name: 'Settlement',
      slug: 'settlement',
      description: 'settlement test description',
      product: 'internet-payments',
      exports: {
        csv: true,
        html: false,
        pdf: true,
        xls: true
      },
      subscribable: true,
      category: 'financial',
      custom: false,
      isHidden: false,
      isPaginated: true,
      menchiesOnly: false,
      params: {
        end: {
          date: true,
          time: false,
          type: 'date'
        },
        start: {
          date: true,
          time: false,
          type: 'date'
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: false,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PW2',
          rangesEnabled: ['BUSINESS_DAY.CURRENT', 'BUSINESS_DAY.LAST', 'WEEK.TO_DATE', 'WEEK.LAST']
        }
      }
    }, {
      name: 'Batch Transaction Detail',
      slug: 'batch-details',
      description: 'batch transaction detail test description',
      product: 'internet-payments',
      exports: {
        csv: true,
        html: false,
        pdf: true,
        xls: true
      },
      subscribable: true,
      category: 'financial',
      custom: false,
      isHidden: false,
      isPaginated: true,
      menchiesOnly: false,
      params: {
        end: {
          date: true,
          time: false,
          type: 'date'
        },
        start: {
          date: true,
          time: false,
          type: 'date'
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: false,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PW2',
          rangesEnabled: ['BUSINESS_DAY.CURRENT', 'BUSINESS_DAY.LAST', 'WEEK.TO_DATE', 'WEEK.LAST']
        }
      }
    }, {
      name: 'Dispute',
      slug: 'dispute',
      description: 'dispute test description',
      product: 'internet-payments',
      exports: {
        csv: true,
        html: false,
        pdf: true,
        xls: true
      },
      subscribable: true,
      category: 'operational',
      custom: false,
      isHidden: false,
      isPaginated: true,
      menchiesOnly: false,
      params: {
        end: {
          date: true,
          time: false,
          type: 'date'
        },
        start: {
          date: true,
          time: false,
          type: 'date'
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: false,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PW2',
          rangesEnabled: ['BUSINESS_DAY.CURRENT', 'BUSINESS_DAY.LAST', 'WEEK.TO_DATE', 'WEEK.LAST']
        }
      }
    }, {
      name: 'Transactions',
      slug: 'transaction',
      description: 'transaction test description',
      product: 'internet-payments',
      exports: {
        csv: true,
        html: false,
        pdf: true,
        xls: true
      },
      subscribable: true,
      category: 'operational',
      custom: false,
      isHidden: false,
      isPaginated: true,
      menchiesOnly: false,
      params: {
        end: {
          date: true,
          time: false,
          type: 'date'
        },
        start: {
          date: true,
          time: false,
          type: 'date'
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: false,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PW2',
          rangesEnabled: ['BUSINESS_DAY.CURRENT', 'BUSINESS_DAY.LAST', 'WEEK.TO_DATE', 'WEEK.LAST']
        }
      }
    }, {
      name: 'Funds in Transit',
      slug: 'funds-in-transit',
      description: 'Report that tracks funds that have been recorded by the merchant as paid, but not yet deposited to their bank account as a payout. It can be run daily or monthly.',
      product: 'internet-payments',
      exports: {
        csv: true,
        html: false,
        pdf: true,
        xls: true
      },
      subscribable: true,
      category: 'financial',
      custom: false,
      isHidden: false,
      isPaginated: true,
      menchiesOnly: false,
      params: {
        end: {
          date: true,
          time: false,
          type: 'date'
        },
        start: {
          date: true,
          time: false,
          type: 'date'
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: true,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PT24H',
          rangesEnabled: []
        }
      }
    }, {
      name: 'Reserve Balance',
      slug: 'reserve-balance',
      description: 'Report that tracks daily and monthly starting and ending totals in a merchant’s Reserve Balance.',
      product: 'internet-payments',
      exports: {
        csv: true,
        html: false,
        pdf: true,
        xls: true
      },
      subscribable: true,
      category: 'financial',
      custom: false,
      isHidden: false,
      isPaginated: true,
      menchiesOnly: false,
      params: {
        end: {
          date: true,
          time: false,
          type: 'date'
        },
        start: {
          date: true,
          time: false,
          type: 'date'
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: false,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PW2',
          rangesEnabled: ['BUSINESS_DAY.CURRENT', 'BUSINESS_DAY.LAST', 'WEEK.TO_DATE', 'WEEK.LAST']
        }
      }
    }]
  };
  var _default = INTERNET_PAYMENTS_REPORT_LIST_FIXTURE;
  _exports.default = _default;
});