define("lh4/routes/general-create-route", ["exports", "lh4/mixins/changeset-transition-route-mixin", "ember-changeset", "lh4/utils/validations"], function (_exports, _changesetTransitionRouteMixin, _emberChangeset, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    isEproColorPickerFeatureFlagEnabled: function isEproColorPickerFeatureFlagEnabled() {
      return this.can.can('view menu color picker in feature-flag');
    },
    setupController: function setupController(controller, model) {
      var _clearCustomModel$bin,
          _this = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      var modelName = this.modelName,
          childChangesets = this.childChangesets,
          clearCustomModel = this.clearCustomModel;
      var changeset = this.buildChildChangeset(model[modelName]); // clears main model created in create routes in case model was not saved

      var clearTemporaryMainModel = function clearTemporaryMainModel() {
        if (model[modelName].isNew) {
          model[modelName].unloadRecord();
        }
      };

      controller.setProperties({
        changeset: changeset,
        clearTemporaryData: (_clearCustomModel$bin = clearCustomModel === null || clearCustomModel === void 0 ? void 0 : clearCustomModel.bind(null, model, modelName)) !== null && _clearCustomModel$bin !== void 0 ? _clearCustomModel$bin : clearTemporaryMainModel
      });

      if (!childChangesets) {
        return;
      } // set loaded data on controller to check if anything changed when exiting


      var childChangesetsSnapshotMap = {}; // build child changesets

      Object.keys(childChangesets).forEach(function (childModelName) {
        var childChangesetName = childChangesets[childModelName];
        changeset[childChangesetName] = model[modelName][childModelName].map(_this.buildChildChangeset);
        childChangesetsSnapshotMap[childChangesetName] = changeset[childChangesetName].map(function (c) {
          return JSON.stringify(c.data.toJSON());
        });
      }); // Clears new models and changesets after save
      // to prevent unsaved changes detection and to cleanup objects that
      // are no longer used

      var clearTemporaryData = function clearTemporaryData() {
        Object.keys(childChangesets).forEach(function (childModelName) {
          var childChangesetName = childChangesets[childModelName]; // unload new records that were created when creating new childChangesets

          model[modelName][childModelName].filter(function (m) {
            return m.isNew;
          }).forEach(function (m) {
            return m.unloadRecord();
          }); // update childChangesetsSnapshots to match childChangesets
          // this prevents false positives when detecting unsaved changes

          childChangesetsSnapshotMap[childChangesetName] = changeset[childChangesetName].map(function (c) {
            return JSON.stringify(c.data.toJSON());
          });
        }); // used after clearing child records as unloading main model leaves child models in !isNew state

        clearTemporaryMainModel();
      };

      controller.setProperties({
        childChangesetsSnapshotMap: childChangesetsSnapshotMap,
        clearTemporaryData: clearTemporaryData,
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        }
      });
    },
    buildChildChangeset: function buildChildChangeset(model) {
      var _buildChangesetDepend = (0, _validations.buildChangesetDependentValidations)(model),
          validateFn = _buildChangesetDepend.validateFn,
          validationMap = _buildChangesetDepend.validationMap;

      return new _emberChangeset.default(model, validateFn, validationMap, {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});