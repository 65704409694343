define("lh4/routes/authenticated/epro/accounting/taxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    location: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: {
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      searchName: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var location = this.location.model.id;
      var queryParams = {
        location: location,
        limit: 'all',
        order: 'name',
        sort: 'asc'
      };
      return Ember.RSVP.hash({
        taxes: this.store.query('echo-pro/tax', _objectSpread(_objectSpread({}, params), {}, {
          location: location
        })),
        items: this.store.query('echo-pro/item', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,taxSetGuid,isActive'
        })),
        orderTypes: this.store.query('echo-pro/order-type', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,isActive'
        })),
        taxSets: this.store.query('echo-pro/accounting/tax-set', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,isAllLiquor'
        }))
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        headers: [{
          name: this.intl.t('controllers:authenticated:epro:accounting:taxes:headers:name'),
          field: 'name',
          width: 'five',
          sortable: true
        }, {
          name: this.intl.t('controllers:authenticated:epro:accounting:taxes:headers:amount'),
          field: 'value',
          width: 'five',
          aligned: 'right',
          sortable: true,
          type: 'number'
        }, {
          name: this.intl.t('controllers:authenticated:epro:accounting:taxes:headers:status'),
          field: 'active',
          width: 'six',
          sortable: true,
          type: 'active'
        }]
      });
    }
  });

  _exports.default = _default;
});